import _ from '@lodash';
import { Arrow } from 'react-konva';
import { useWorkflow } from '../WorkflowProvider';
import { validationLineColor } from '../WorkflowUtil';

export const getCalculatePipeValidationValue = (calculate, name) => {
	if (calculate != null && calculate.transformer && _.isArray(calculate.transformer.pipe_validations)) {
		let result = null;
		calculate.transformer.pipe_validations.forEach(pipeValidation => {
			if (pipeValidation[name] && pipeValidation[name].entries.length > 0) {
				result = pipeValidation[name];
			}
		});
		return result;
	}
	return null;
};

function WorkflowLine(props) {
	const { item } = props;
	const { editMode, underEditing, deleteLine, calculate, setFloatValidation } = useWorkflow();
	const validation = getCalculatePipeValidationValue(calculate, item.key);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			deleteLine(item.key);
		}
	};

	return (
		<Arrow
			onPointerClick={() => handleItemClick()}
			stroke={validationLineColor(validation)}
			fill={validationLineColor(validation)}
			bezier
			hitStrokeWidth={10}
			strokeWidth={3}
			points={[item.start.pos.x, item.start.pos.y, item.start.pos.x + 50, item.start.pos.y, item.end.pos.x - 50, item.end.pos.y, item.end.pos.x, item.end.pos.y]}
			listening={underEditing === null}
			onPointerEnter={() => {
				if (validation) setFloatValidation({ name: item.key, value: validation });
			}}
			onPointerLeave={() => {
				if (validation) setFloatValidation(null);
			}}
		/>
	);
}

export default WorkflowLine;
