import { useEffect, useRef, useState } from 'react';
import FusePageCarded from '@fuse/core/FusePageCarded/FusePageCarded';
import { Typography, IconButton, Icon, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { motion } from 'framer-motion';
import { FaChevronCircleRight, FaExternalLinkAlt } from 'react-icons/fa';

import TaskManagerResultGallery from 'app/admin/taskManager/component/TaskManagerResultGallery';
import TaskManagerService from 'app/admin/taskManager/service/TaskManagerService';
import AspectEditService from 'app/admin/aspect/service/AspectEditService';
import TransformerEditService from 'app/admin/workflow/service/TransformerEditService';

import { formatLabelValue } from 'app/admin/common/util/CommonUtil';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';

import Sidebar from '../common/component/Sidebar';
import CompareTable from '../common/component/CompareTable';
// import AttributeEditTable from '../common/component/AttributeEditTable';
import ChartPopup from '../common/component/ChartPopup';
import DynamicAttributeTable from '../common/component/DynamicAttributeTable';
import OptimizationPipelineEda from './component/OptimizationPipelineEda';

import { useOptimizationPipeline } from './component/OptimizationPipelineProvider';

function OptimizationPipeline(props) {
	// const { id } = props.item;

	const id = 10054;
	const aspectId = 22;
	const transformerId = 22;
	const optimCaseId = 16;

	// const id = 10054;
	// const aspectId = 1;
	// const transformerId = 6;
	// const optimCaseId = 4;

	const [showGallery, setShowGallery] = useState(false);
	const [galleryGroupName, setGalleryGroupName] = useState(null);

	const [modal, setModal] = useState(null);

	const [response, setResponse] = useState(null);

	const { sidebar, setSidebar, popup, setPopup } = useOptimizationPipeline();

	useEffect(() => {
		TaskManagerService.getResult(id).then(resp => setResponse(resp));
	}, [id]);

	useEffect(() => {
		if (!showGallery) setGalleryGroupName(null);
	}, [showGallery]);

	useEffect(() => {
		console.log(popup);
	}, [popup]);

	const [firstElementWidth, setFirstElementWidth] = useState(0);
	const [lastElementWidth, setLastElementWidth] = useState(0);

	const firstElement = useRef(null);
	const lastElement = useRef(null);

	useEffect(() => {
		const observer1 = new ResizeObserver(entries => { setFirstElementWidth(entries[0].borderBoxSize[0].inlineSize) });
		observer1.observe(firstElement.current);

		const observer2 = new ResizeObserver(entries => { setLastElementWidth(entries[0].borderBoxSize[0].inlineSize) });
		observer2.observe(lastElement.current);

		return () => {
			firstElement.current && observer1.unobserve(firstElement.current);
			lastElement.current && observer2.unobserve(lastElement.current)
		}
	}, []);

	return (
		<>

			{showGallery && <TaskManagerResultGallery item={{ id, groupName: galleryGroupName }} onClose={() => setShowGallery(false)} />}

			<FusePageCarded
				classes={{
					toolbar: 'p-0',
					header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
				}}
				header={
					<div className='flex items-center max-w-full'>
						<div className='flex flex-col min-w-0 mx-8 sm:mc-16'>
							<motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
								<Typography className='hidden sm:flex text-16 md:text-24 mx-12 font-semibold'>Optimization Pipeline</Typography>
							</motion.div>
						</div>
					</div>
				}
				content={
					<div className='min-h-full p-16 sm:p-24 flex items-center justify-center'>

						{popup && popup === 'TIMESERIES' && <TimeseriesChartPopup onClose={() => setPopup(null)} />}

						<Sidebar>
							{sidebar === 'UNIT' && <UnitDisplay data={response && response.unit_review.original_state} />}
							{sidebar === 'BEST_STATE' && <CompareBestState dataLeft={response && response.optimizer.actual_state} dataRight={response && response.optimizer.best_state} />}
							{/* {sidebar === 'BEST_STATE' && <BestStateDisplay data={response && response.optimizer.best_state} unitData={response && response.unit_review.original_state} />} */}
							{sidebar === 'LOSS_DETAILS' && <LossDetailsDisplay data={response && response.model_fit.mse_vector} />}
							{sidebar === 'COMPARE_DATA' && <CompareData dataLeft={response && response.data_clean.original_state} dataRight={response && response.data_clean.cleansed_state} />}
							{sidebar === 'COMPARE_MODEL' && <CompareModel dataLeft={response && response.model_fit.actual_state} dataRight={response && response.model_fit.predicted_state} />}
							{sidebar === 'MODEL_INJECT' && <EditAspect id={aspectId} onClose={() => setSidebar(null)} />}
							{sidebar === 'OPTIMIZER_INJECT' && <EditTransformer id={transformerId} onClose={() => setSidebar(null)} />}
						</Sidebar>

						{(modal && modal === 'EDA') && <OptimizationPipelineEda onClose={() => setModal(null)} />}

						<div className={'relative w-full max-w-3xl flex items-center gap-16 transition-transform transform duration-500 ' + (sidebar ? '' : '')}>

							<CustomArrow firstElementWidth={firstElementWidth} lastElementWidth={lastElementWidth} />

							<div className='flex flex-col items-center rounded-2xl shadow-lg bg-grey-50 border min-w-128 overflow-hidden p-16 z-10' ref={firstElement}>

								<div className='flex-1 flex flex-col items-center gap-16 px-16 py-72'>
									<div className='text-3xl font-bold transform opacity-25'>Unit</div>
									<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => setSidebar('UNIT')}>Show data</Button>
								</div>

								<div className='w-full flex flex-col gap-8 self-end'>
									<ValueIndicator small label='Actual date' value={response ? response.unit_review.original_state.actual_date : '-'} />
									<ValueIndicator label='Aggregation unit' value={response ? response.unit_review.original_state.aggregation_unit : '-'} />
								</div>

							</div>

							<Arrow />

							<Element label='Data validation'>

								<ElementBlock>
									<BooleanIndicator label='Rule based anomaly' state={response && response.novelty_detection.is_novelty} reverse />
									<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => { /*setGalleryGroupName('time_series'); setShowGallery(true);*/setPopup('TIMESERIES') }}>Timeseries</Button>
									<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => { setGalleryGroupName('histogram'); setShowGallery(true); }}>Histogram</Button>
								</ElementBlock>

								<ElementBlock label='Expert info'>
									<ValueIndicator label='Anomaly value' value={response ? Math.round(response.novelty_detection.out_distribution_percentage * 100) / 100 + '%' : '-'} />
									<ValueIndicator label='Anomaly threshold' value={response ? response.novelty_detection.novelty_threshold : '-'} />
									<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => { setSidebar('COMPARE_DATA') }}>Compare</Button>
								</ElementBlock>

							</Element>

							<Arrow force />

							<div className='flex flex-col gap-16 z-10 self-start'>

								<Element label='Model inject'>

									<ElementBlock>
										<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => setSidebar('MODEL_INJECT')}>Edit data</Button>
									</ElementBlock>

								</Element>

								<Arrow vertical />

								<Element label='Model validation'>

									<ElementBlock>
										<BooleanIndicator label='Accurate' state={response && response.model_fit.is_accurate} />
										<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => setSidebar('COMPARE_MODEL')}>Compare model</Button>
									</ElementBlock>

									<ElementBlock label='Expert info'>
										<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => setSidebar('LOSS_DETAILS')}>Loss details</Button>
									</ElementBlock>

								</Element>

							</div>

							<Arrow force />

							<div className='flex flex-col gap-16 z-10 self-start' ref={lastElement}>

								<Element label='Optimizer inject'>

									<ElementBlock>
										<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => setSidebar('OPTIMIZER_INJECT')}>Edit data</Button>
									</ElementBlock>

								</Element>

								<Arrow vertical />

								<Element label='Optimizer' link={'/optimization-case/edit/' + optimCaseId}>

									<ElementBlock>
										<ValueIndicator label='Best value' value={response ? formatLabelValue(response.optimizer.best_value, {
											thousandsSeparator: true,
											decimalPlaces: 4,
											displayAsPercentage: false,
											showUnit: false
										}) : '-'} />
										<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => setSidebar('BEST_STATE')}>Best state</Button>
									</ElementBlock>

									<ElementBlock label='Expert info'>
										<ValueIndicator label='Step' value={response ? Object.getOwnPropertyNames(response.optimizer.actual_state).length : '-'} />
										<ValueIndicator label='Time needed' value={response ? Math.round(response.optimizer.elapsed_time * 100) / 100 : '-'} />
										<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => { setGalleryGroupName('Optimizer'); setShowGallery(true); }}>Trajectory plots</Button>
										<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' disabled onClick={() => { }}>SHAP</Button>
									</ElementBlock>

								</Element>

							</div>

						</div>

					</div>

				}
			/>
		</>
	);
}

function Element(props) {
	return (
		<div className='flex flex-col items-start rounded-2xl shadow-lg bg-grey-50 border min-w-128 overflow-hidden z-10'>

			<div className='min-w-full flex items-start justify-between gap-16'>

				<div className={'border-b border-r bg-white rounded-br-2xl text-lg leading-normal font-bold px-16 py-12 ' + (props.link ? '' : 'mr-32')}>
					{props.label}
				</div>

				{props.link && <Link to={props.link} className='border-b border-l bg-black group rounded-bl-xl font-bold px-12 py-12'>
					<FaExternalLinkAlt className='text-white opacity-75 group-hover:opacity-100' />
				</Link>}

			</div>

			{props.children}

		</div>
	);
}

function Indicator(props) {
	return (
		<div className='w-full flex items-center gap-4 bg-white shadow-lg px-6 py-6 rounded-full'>

			<div className={'flex-1 font-bold px-8 ' + (props.small ? 'text-10' : 'text-10 xl:text-xs')}>{props.label}</div>

			{props.children}

		</div>
	);
}

function BooleanIndicator(props) {
	const greenClassName = 'bg-green-700 border-green-800 text-white font-bold shadow-sm';
	const redClassName = 'bg-red-700 border-red-800 text-white font-bold shadow-sm';

	return (
		<Indicator label={props.label}>

			<div className={'rounded-full text-xs px-6 py-2 border ' + (props.state ? (props.reverse ? redClassName : greenClassName) : 'bg-grey-100')}>Yes</div>
			<div className={'rounded-full text-xs px-6 py-2 border ' + (!props.state ? (props.reverse ? greenClassName : redClassName) : 'bg-grey-100')}>No</div>

		</Indicator >
	);
};

function ValueIndicator(props) {
	return (
		<Indicator label={props.label}>

			<div className={'rounded-full text-10 px-8 py-2 border bg-grey-100 ' + (props.small ? 'text-10' : 'text-10 xl:text-xs')}>{(props.value !== null && props.value !== 'undefined') ? props.value : 'N/A'}</div>

		</Indicator>
	);
};

function ElementBlock(props) {
	return (
		<div className='w-full flex flex-col gap-10 p-10 border-b last:border-b-0 shadow-xl'>

			{props.label && <div className='text-xs font-bold opacity-50 px-10'>{props.label}</div>}

			{props.children}

		</div>
	);
}

function Arrow(props) {
	return (
		<div className={'relative flex-1 flex items-center ' + (props.vertical ? 'min-h-40 flex-col' : 'min-w-64 flex-row')}>

			<div className={'rounded-full w-10 h-10 bg-grey-300 ' + (props.vertical ? '' : '')} />
			<div className={'flex-1 bg-grey-300 ' + (props.vertical ? 'w-2 pb-16' : 'h-2 pr-16')} />
			<FaChevronCircleRight className={'text-grey-300 text-xs ' + (props.vertical ? 'transform rotate-90' : '')} />

			{props.force && <div className='absolute w-full h-0 flex items-center justify-center'>

				<div className='bg-grey-200 rounded-full p-4'>
					<IconButton className='w-24 h-24' aria-label='Force' size='small' onClick={() => { }}>
						<Icon>chevron_right</Icon>
					</IconButton>
				</div>

			</div>}

		</div>
	);
}

function CustomArrow(props) {
	return (
		<div className='absolute bottom-0 left-0 w-full h-24 mb-24' style={{ paddingLeft: (props.firstElementWidth / 2) + 'px', paddingRight: (props.lastElementWidth + 24) + 'px' }}>
			<div className='relative w-full h-full border-b-2 border-l-2 border-grey-300 rounded-bl-2xl'>

				<FaChevronCircleRight className='absolute -top-12 -left-6 text-grey-300 text-xs transform -rotate-90' />
				<div className='absolute -bottom-6 -right-5 rounded-full w-10 h-10 bg-grey-300' />

				<div className='absolute bottom-0 w-full h-0 flex items-center justify-center'>

					<div className=' bg-white rounded-t-2xl p-8 flex gap-8'>

						<div className='relative'>
							<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' disabled onClick={() => { }}>Update factory state</Button>
							<div className='absolute w-full -bottom-20 text-xs font-bold opacity-30 text-center'>Coming soon</div>
						</div>

						<Button className='whitespace-nowrap' variant='contained' color='primary' size='small' onClick={() => { }}>Preview</Button>

					</div>

				</div>

			</div>
		</div>
	);
};

function CompareData(props) {
	return (
		<CompareTable
			titleLeft='Original State'
			titleRight='Cleansed State'
			dataLeft={props.dataLeft}
			dataRight={props.dataRight}
		/>
	);
};

function CompareBestState(props) {
	return (
		<CompareTable
			titleRight='Optimal State'
			titleLeft='Cleansed State'
			dataRight={props.dataLeft}
			dataLeft={props.dataRight}
		/>
	);
};

function CompareModel(props) {
	return (
		<CompareTable
			titleLeft='Actual State'
			titleRight='Predicted State'
			dataLeft={props.dataLeft}
			dataRight={props.dataRight}
		/>
	);
};

function LossDetailsDisplay(props) {
	const [list, setList] = useState([]);

	useEffect(() => {
		if (props.data)
			setList(processData(props.data));
	}, [props.data]);

	const processData = (data) => {
		let _list = [];

		for (let key in data) {
			const item = data[key];
			let row = {};

			row.attribute = key;
			row.value = item;

			_list.push(row);
		}

		return _list;
	};

	return (
		<DynamicAttributeTable
			title='Loss details'
			columns={[
				{ 'key': 'attribute', 'title': 'Attribute', 'config': { 'sortable': true, 'header': { 'className': '' }, 'field': { 'className': '' } } },
				{ 'key': 'value', 'title': 'Value', 'config': { 'sortable': true, 'header': { 'className': 'justify-end' }, 'field': { 'className': 'justify-end', 'format': defaultLabelStyle() } } },
			]}
			list={list}
			onClose={props.onClose}
		/>
	);
};

function UnitDisplay(props) {
	const [list, setList] = useState([]);

	useEffect(() => {
		if (props.data)
			setList(processData(props.data));
	}, [props.data]);

	const processData = (data) => {
		let _list = [];

		for (let key in data) {
			const item = data[key];
			if (item instanceof Object) {
				let row = {};

				row.attribute = key;
				if (item.value) row.value = item.value;
				if (item.uom && item.uom !== 'No UOM found') row.uom = item.uom;

				_list.push(row);
			}
		}

		return _list;
	};

	return (
		<DynamicAttributeTable
			title='Unit'
			columns={[
				{ 'key': 'attribute', 'title': 'Attribute', 'config': { 'sortable': true, 'header': { 'className': '' }, 'field': { 'className': '' } } },
				{ 'key': 'uom', 'title': 'Unit of measurement', 'config': { 'header': { 'className': 'justify-end' }, 'field': { 'className': 'justify-end' } } },
				{ 'key': 'value', 'title': 'Value', 'config': { 'sortable': true, 'header': { 'className': 'justify-end' }, 'field': { 'className': 'justify-end', 'format': defaultLabelStyle() } } },
			]}
			list={list}
			onClose={props.onClose}
		/>
	);
};

function EditAspect(props) {
	const [response, setResponse] = useState(null);
	const [list, setList] = useState([]);

	const [selectedEditField, setSelectedEditField] = useState(null);

	useEffect(() => {
		AspectEditService.getData(props.id).then(resp => setResponse(resp));
	}, [props.id]);

	useEffect(() => {
		if (response)
			setList(processData(response.input_attributes));
	}, [response]);

	const processData = (data) => {
		let _list = [];

		for (let i = 0; i < data.length; i += 1) {
			const item = data[i];
			let row = {};

			if (item.name) row.attribute = item.name;
			if (item.value) row.default = item.value;
			if (item.value) row.input = item.value;

			_list.push(row);
		}

		return _list;
	};

	return (
		<DynamicAttributeTable
			title='Model Inject'
			editable
			columns={[
				{ 'key': 'attribute', 'title': 'Attribute', 'config': { 'header': { 'className': '' }, 'field': { 'className': 'text-xs' } } },
				{ 'key': 'cleansed', 'title': 'Cleansed value', 'config': { 'selectable': true, 'header': { 'className': 'justify-end' }, 'field': { 'className': 'justify-end text-xs', 'format': defaultLabelStyle() } } },
				{ 'key': 'default', 'title': 'Model default', 'config': { 'selectable': true, 'selectDefault': true, 'header': { 'className': 'justify-end' }, 'field': { 'className': 'justify-end text-xs', 'format': defaultLabelStyle() } } },
				{ 'key': 'input', 'title': 'Edit value', 'config': { 'header': { 'className': 'justify-end' }, 'field': { 'className': '', 'custom': { 'type': 'INPUT', 'valueKey': 'default' } } } },
			]}
			list={list}
			onClose={props.onClose}
		/>
	);
};

function EditTransformer(props) {
	const [response, setResponse] = useState(null);
	const [list, setList] = useState([]);

	const [selectedEditField, setSelectedEditField] = useState(null);

	useEffect(() => {
		TransformerEditService.getData(props.id).then(resp => setResponse(resp));
	}, [props.id]);

	useEffect(() => {
		if (response)
			setList(processData(response.export.input_attributes));
	}, [response]);

	const processData = (data) => {
		let _list = [];

		for (let i = 0; i < data.length; i += 1) {
			const item = data[i];
			let row = {};

			if (item.name) row.attribute = item.name;
			if (item.value) row.value = item.value;

			_list.push(row);
		}

		return _list;
	};

	return (
		<DynamicAttributeTable
			title='Optimizer Inject'
			editable
			columns={[
				{ 'key': 'attribute', 'title': 'Attribute', 'config': { 'header': { 'className': '' }, 'field': { 'className': 'text-xs' } } },
				// { 'key': 'cleansed', 'title': 'Cleansed value', 'config': { 'selectable': true, 'selected': selectedEditField === 'cleansed', 'onSelect': () => { setSelectedEditField('cleansed') }, 'header': { 'className': 'justify-end' }, 'field': { 'className': 'justify-end text-xs', 'format': defaultLabelStyle() } } },
				{ 'key': 'value', 'title': 'Original value', 'config': { 'header': { 'className': 'justify-end' }, 'field': { 'className': 'justify-end text-xs', 'format': defaultLabelStyle() } } },
				{ 'key': 'input', 'title': 'Edit value', 'config': { 'header': { 'className': 'justify-end' }, 'field': { 'className': '', 'custom': { 'type': 'INPUT', 'valueKey': 'default' } } } },
			]}
			list={list}
			onClose={props.onClose}
		/>
	);
};

function TimeseriesChartPopup(props) {
	return (
		<ChartPopup onClose={props.onClose} />
	)
};

export default withRouter(OptimizationPipeline);