import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import TaskManagerScheduleButton from '../component/TaskManagerScheduleButton';
import TaskManagerTableService from '../service/TaskManagerTableService';
import TaskManagerResultButton from '../component/TaskManagerResultButton';
import TaskManagerFileListButton from '../component/TaskManagerFileListButton';
import TaskManagerEnabledButton from '../component/TaskManagerEnabledButton';
import DeleteErrorneousButton from '../component/DeleteErroneousButton';

const config = {
	key: 'taskManagerTable',
	topic: 'taskManager',
	service: TaskManagerTableService,
	toolbarAction: ['filter', 'refresh'],
	rowAction: ['delete'],
	rowButtonList: [{ key: 'updateScheduler', component: TaskManagerScheduleButton }],
	handleDelete: (sessionName, item) => TaskManagerTableService.deleteByName(sessionName, item.name),
	toolbarButtonList: [
		{
			key: 'deleteErroneous',
			place: 'before',
			component: DeleteErrorneousButton
		}
	],
	filterFields: [
		{
			text: 'Status',
			propertyName: 'status',
			propertyType: 'String',
			type: 'EQ'
		}
	],
	columns: [
		{
			key: 'name'
		},
		{
			key: 'user_name'
		},
		{
			key: 'file_count',
			sortable: false,
			component: TaskManagerFileListButton
		},
		{
			key: 'schedule',
			sortable: false
		},
		{
			key: 'type'
		},
		{
			key: 'file_count',
			sortable: false,
			component: TaskManagerFileListButton
		},
		{
			key: 'ok_count'
		},
		{
			key: 'fail_count'
		},
		{
			key: 'last_started',
			type: 'dateTime'
		},
		{
			key: 'last_status'
		},
		{
			key: 'last_timestamp',
			type: 'dateTime'
		},
		{
			key: 'last_message'
		},
		{
			key: 'last_result'
		},

		// {
		// 	key: 'ts',
		// 	type: 'dateTime'
		// },
		// {
		// 	key: 'user_name'
		// },
		// {
		// 	key: 'result',
		// 	sortable: false,
		// 	component: TaskManagerResultButton
		// },
		// {
		// 	key: 'file_count',
		// 	sortable: false,
		// 	component: TaskManagerFileListButton
		// },
		// {
		// 	key: 'ts_result',
		// 	type: 'dateTime'
		// },
		// {
		// 	key: 'message',
		// 	sortable: false
		// },
		{
			key: 'enabled',
			sortable: false,
			component: TaskManagerEnabledButton
		}
	]
};

function TaskManagerTable() {
	return <TableBrowser config={config} />;
}
export default withReducer('taskManagerTable', reducer('taskManagerTable'))(TaskManagerTable);
