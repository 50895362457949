import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormControl, Select, FormHelperText, InputLabel, MenuItem, ListItemText, Checkbox } from '@material-ui/core';
import { Lock, ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';
import { determineEditableAccess } from '../EditFormUtils';

function EditFormSelectField(props) {
	const methods = useFormContext();
	const { control, getValues } = methods;
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { error } = props.fieldState;
	const { value, onChange } = props.field;
	const [items, setItems] = useState(null);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const valueKey = determineValueKey(props.fieldConfig);
	const labelKey = determineLabelKey(props.fieldConfig);
	const hideNull = props.fieldConfig.hideNull || false;
	const { compact = false } = props;

	const parseFilterKey = () => {
		if (props.fieldConfig.filterKey) {
			if (_.isArray(props.fieldConfig.filterKey)) {
				return props.fieldConfig.filterKey.map(f => (!f.startsWith('^') ? (props.fieldPrefix || '') + f : f.substring(1)));
			}
			return !props.fieldConfig.filterKey.startsWith('^') ? (props.fieldPrefix || '') + props.fieldConfig.filterKey : props.fieldConfig.filterKey.substring(1);
		}
		return null;
	};
	const filterKey = parseFilterKey();

	const filterValue = useWatch({
		control,
		name: filterKey,
		disabled: filterKey == null
		// defaultValue: null TODO: gondot okoz ha benne van
	});

	const updateItems = () => {
		if (_.isFunction(props.fieldConfig.items)) {
			props.fieldConfig.items(props.fieldConfig.itemsParameterIsDto ? getValues() : filterValue).then(result => {
				setItems(result || []);
			});
		} else if (Array.isArray(props.fieldConfig.items)) {
			setItems(props.fieldConfig.items);
		} else {
			setItems([]);
		}
	};

	useEffect(() => {
		updateItems();
	}, [props.fieldConfig, filterValue]);

	const renderValue = selected => {
		if (_.isArray(selected))
			return items
				.filter(d => selected.indexOf(d[valueKey]) > -1)
				.map(d => d[labelKey])
				.join(', ');
		if (!_.isUndefined(selected) && selected !== null && items) {
			const itemIndex = items.findIndex(d => selected === d[valueKey]);
			if (itemIndex > -1) return items[itemIndex][labelKey];
		}
		return '';
	};

	useEffect(() => {
		if (_.isFunction(props.fieldConfig.onChange)) props.fieldConfig.onChange(value, props.field, methods);
	}, [value]);

	const selectLabel = () => {
		return compact ? undefined : !_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`);
	};

	return (
		<FormControl className={clsx(compact ? 'w-full' : 'w-full mt-8 mb-16', props.fieldConfig.className)} variant="outlined" error={error} required={props.fieldConfig.required}>
			{!compact && <InputLabel id={`${props.field.name}-label`}>{selectLabel()}</InputLabel>}
			{items != null ? (
				<Select
					labelId={compact ? null : `${props.field.name}-label`}
					id={props.field.name}
					multiple={!!props.fieldConfig.multiple}
					label={selectLabel()}
					value={!!props.fieldConfig.multiple && value === null ? [] : (value || '')}
					defaultValue={props.fieldConfig.multiple ? [] : null}
					onChange={e => onChange(e.target.value)}
					inputProps={{ readOnly }}
					renderValue={selected => renderValue(selected)}
					IconComponent={readOnly ? Lock : ArrowDropDown}
					displayEmpty={!hideNull}
				>
					{!hideNull ? <MenuItem value={null}>{props.fieldConfig.emptyLabel || t('SELECT_EMPTY')}</MenuItem> : null}
					{props.fieldConfig.multiple
						? items.map((item, i) => (
							<MenuItem key={item[valueKey]} value={item[valueKey]}>
								<Checkbox checked={value && value.indexOf(item[valueKey]) > -1} />
								<ListItemText primary={item[labelKey]} />
							</MenuItem>
						))
						: items.map((item, i) => (
							<MenuItem key={item[valueKey]} value={item[valueKey]}>
								{item[labelKey]}
							</MenuItem>
						))}
				</Select>
			) : null}
			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</FormControl>
	);
}

export const determineValueKey = fieldConfig => {
	return !_.isUndefined(fieldConfig.type) && fieldConfig.type === 'enum' ? 'value' : 'id';
};

export const determineLabelKey = fieldConfig => {
	return !_.isUndefined(fieldConfig.type) && fieldConfig.type === 'enum' ? 'label' : 'name';
};

export default EditFormSelectField;
