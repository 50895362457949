import _ from '@lodash';
import { useTranslation } from 'react-i18next';

const tdClassName = 'px-12 first:pl-24 last:pr-24 bg-gray-100 first:rounded-l-xl last:rounded-r-xl';

export default function EditFormGridHeader(props) {
	const { config, fieldConfig } = props;
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const fields = fieldConfig.fields.filter((item, index) => fieldConfig.fields.findIndex(d => d.key === item.key) === index);
	const modifyOnly = fieldConfig.modifyOnly || false;

	return (
		<tr className="sticky top-0 z-10 h-48 text-left">
			{fieldConfig.orderable && <th className={'font-medium ' + tdClassName}>#</th>}
			{fields && fields.map((f, i) => (<th className={'font-medium ' + tdClassName} key={`field-${i}`}>{!_.isUndefined(f.text) ? f.text : t(`${config.topic}:${f.key}`)}{f.required ? '*' : ''}</th>))}
			{!modifyOnly && (<th className={'font-medium text-11 ' + tdClassName} style={{ width: '20px' }}>{/*t('DELETE')*/''}</th>)}
		</tr>
	);
}
