import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { Group, Rect, Text, Circle, Line } from 'react-konva';
import { useWorkflow } from '../WorkflowProvider';
import { findValueInCalculate, roundCoord } from '../WorkflowUtil';
import WorkflowInputValue from './WorkflowInputValue';
import WorkflowOutputValue from './WorkflowOutputValue';

export const width = 150;
export const height = 100;

function WorkflowValue(props) {
	const { item } = props;
	const [isDragging, setIsDragging] = useState(false);
	const { data, setData, editMode, checkStageCorner, initLine, underEditing, setUnderEditing, setShowPropertiesDialog, selected, calculate } = useWorkflow();
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const dragStartData = useRef(null);
	const itemPrevState = useRef(item);

	const calc = findValueInCalculate(calculate, item);

	useEffect(() => {
		setPos({ x: item.x, y: item.y });
		if (pos.x !== item.x || pos.y !== item.y) setPos({ x: item.x, y: item.y });
		itemPrevState.current = item;
	}, [item]);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setData({
				...data,
				line: data.line.filter(d => d.start.key !== item.key && d.end.key !== item.key),
				value: data.value.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'LINE') {
			if (underEditing !== null) {
				if (item.io === 'OUTPUT') {
					const startFromValue = data.value.findIndex(v => v.key === underEditing.start.key);
					if (startFromValue > -1) {
						setData({
							...data,
							line: data.line.filter(d => d.key !== underEditing.key)
						});
						setUnderEditing(null);
					} else {
						setData({
							...data,
							line: data.line.map(d => {
								if (d.key === underEditing.key) {
									return {
										...d,
										end: {
											key: item.key,
											valueKey: underEditing.start.valueKey,
											pos: {
												x: pos.x - 85,
												y: pos.y
											}
										}
									};
								}
								return d;
							})
						});
					}
					setUnderEditing(null);
				} else {
					setData({
						...data,
						line: data.line.filter(d => d.key !== underEditing.key)
					});
					setUnderEditing(null);
				}
			} else if (item.io !== 'OUTPUT') {
				initLine(item.key, item.valueKey, { x: item.io === 'OUTPUT' ? pos.x - 10 : pos.x + 85, y: pos.y });
			}
		}
	};

	const updateDragPos = e => {
		const currentPos = { x: roundCoord(e.target.x()), y: roundCoord(e.target.y()) };
		let deltaX = 0;
		let deltaY = 0;
		const dragStartValue = dragStartData.current.value.find(d => d.key === item.key);
		deltaX = currentPos.x - dragStartValue.x;
		deltaY = currentPos.y - dragStartValue.y;
		if (selected.length > 0 && selected.indexOf(item.key) > -1) {
			setData({
				...data,
				line: data.line.map(d => {
					if (selected.indexOf(d.start.key) > -1 || d.start.key === item.key) {
						const startValue = dragStartData.current.line.find(f => f.key === d.key);
						d.start.pos = { x: startValue.start.pos.x + deltaX, y: startValue.start.pos.y + deltaY };
					}
					if (selected.indexOf(d.end.key) > -1 || d.end.key === item.key) {
						const startValue = dragStartData.current.line.find(f => f.key === d.key);
						d.end.pos = { x: startValue.end.pos.x + deltaX, y: startValue.end.pos.y + deltaY };
					}
					return d;
				}),
				value: data.value.map(d => {
					if (d.key === item.key) {
						return { ...d, ...currentPos };
					}
					if (selected.indexOf(d.key) > -1) {
						const startValue = dragStartData.current.value.find(f => f.key === d.key);
						return { ...d, x: startValue.x + deltaX, y: startValue.y + deltaY };
					}
					return d;
				}),
				transform: data.transform.map(d => {
					if (selected.indexOf(d.key) > -1) {
						const startValue = dragStartData.current.transform.find(f => f.key === d.key);
						return { ...d, x: startValue.x + deltaX, y: startValue.y + deltaY };
					}
					return d;
				})
			});
		} else {
			setData({
				...data,
				line: data.line.map(d => {
					if (d.start.key === item.key) {
						const startValue = dragStartData.current.line.find(f => f.key === d.key);
						d.start.pos = { x: startValue.start.pos.x + deltaX, y: startValue.start.pos.y + deltaY };
					}
					if (d.end.key === item.key) {
						const startValue = dragStartData.current.line.find(f => f.key === d.key);
						d.end.pos = { x: startValue.end.pos.x + deltaX, y: startValue.end.pos.y + deltaY };
					}
					return d;
				}),
				value: data.value.map(d => {
					if (d.key === item.key) {
						return { ...d, ...currentPos };
					}
					return d;
				})
			});
		}
	};

	return (
		<Group
			id={item.key}
			name="value"
			x={pos.x}
			y={pos.y}
			width={width}
			height={height}
			offsetX={width / 2}
			offsetY={height / 2}
			draggable={editMode === 'MOVE'}
			onDragStart={() => {
				setIsDragging(true);
				dragStartData.current = {
					line: data.line.map(d => ({ key: d.key, start: { ...d.start }, end: { ...d.end } })),
					value: data.value.map(d => ({ key: d.key, x: d.x, y: d.y })),
					transform: data.transform.map(d => ({ key: d.key, x: d.x, y: d.y }))
				};
			}}
			onDragMove={e => {
				updateDragPos(e);
				checkStageCorner();
			}}
			onDragEnd={e => {
				setIsDragging(false);
				updateDragPos(e);
				dragStartData.current = null;
			}}
			onPointerDblClick={() => editMode !== 'DELETE' && setShowPropertiesDialog({ class: 'VALUE', key: item.key })}
		>
			<Rect
				onPointerClick={() => handleItemClick()}
				width={width}
				height={height}
				stroke="#bdbdbd"
				strokeWidth={1}
				shadowEnabled
				strokeScaleEnabled={false}
				shadowColor="black"
				shadowOpacity={0.05}
				shadowBlur={15}
				fill={selected.indexOf(item.key) > -1 ? 'lightGreen' : '#fafafa'}
				opacity={1}
				cornerRadius={item.io === 'OUTPUT' ? [3, 20, 20, 3] : [20, 3, 3, 20]}
			/>
			{/* <Rect
				onPointerClick={() => handleItemClick()}
				width={width}
				height={height}
				stroke="darkGreen"
				fill={selected.indexOf(item.key) > -1 ? 'lightGreen' : 'white'}
				opacity={0.8}
				cornerRadius={item.io === 'OUTPUT' ? [0, 30, 30, 0] : [30, 0, 0, 30]}
			/> */}
			{/* <Line stroke="#252f3e" strokeWidth={3} points={item.io === 'OUTPUT' ? [-10, 50, 0, 50] : [150, 50, 160, 50]} /> */}
			<Circle x={item.io === 'OUTPUT' ? -10 : 160} y={50} fill="#1b2330" radius={5} hitStrokeWidth={5} onPointerClick={() => handleItemClick()} />
			{/* <Text text={item.valueKey} fontSize={16} fill="black" fontStyle="bold" width={150} offsetX={5} offsetY={(50 - 12) * -1} padding={5} align="center" listening={false} /> */}
			<Text text={item.valueKey} fontSize={item.valueKey.length > 12 ? 12 : 16} fill="black" fontStyle="bold" width={150} offsetX={0} offsetY={(50 - 12) * -1} padding={5} align="center" listening={false} />
			{calc != null && item.io === 'INPUT' && <WorkflowInputValue calc={calc} name={item.valueKey} />}
			{calc != null && item.io === 'OUTPUT' && <WorkflowOutputValue calc={calc} name={item.valueKey} />}
		</Group>
	);
}

export default WorkflowValue;
