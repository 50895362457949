import _ from '@lodash';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';

import PiMeasurementReaderService from 'app/admin/piMeasurementReader/service/PiMeasurementReaderService';
import MeasurementService from 'app/admin/measurement/service/MeasurementService';
import TaskManagerService from 'app/admin/taskManager/service/TaskManagerService';

export function AddMeasurementTaskDialog(props) {
	const history = useHistory();
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();

	const schema = yup.object().shape({
		readerId: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		measurementId: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	const getDefaultValues = () => {
		let defaultValues = {};
		if (props.readerId) defaultValues.readerId = props.readerId;
		if (props.measurementId) defaultValues.measurementId = props.measurementId;
		return defaultValues;
	};

	const methods = useForm({
		defaultValues: getDefaultValues(),
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleSave = value => {
		TaskManagerService.addPiMeasurementReader(value.measurementId, value.readerId, {}).then(response => handleResponse(response)).catch(err => handleCatch(err));
	};

	const handleResponse = (response) => {
		if (_.isNumber(response))
			history.push(`/task-manager`);
		dispatch(showMessage({ message: 'New Task Manager successfully added' }));
	};

	const handleCatch = (error) => {
		console.log(error);
		dispatch(showMessage({ message: 'Error while creating new Task Manager!' }));
	};

	const handleCancel = () => {
		props.onCancel();
	};

	return (
		<Dialog open fullWidth>

			<DialogTitle disableTypography className="border-b">
				Add Task Manager
			</DialogTitle>

			<DialogContent className="p-20">

				<FormProvider {...methods}>
					<Controller
						control={methods.control}
						name="readerId"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'common' }}
								fieldConfig={{
									key: 'readerId',
									text: 'Reader',
									required: true,
									items: () => PiMeasurementReaderService.getListSelection()
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="measurementId"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'common' }}
								fieldConfig={{
									key: 'measurementId',
									text: 'Measurement',
									required: true,
									items: () => MeasurementService.getListSelection()
								}}
							/>
						)}
					/>
				</FormProvider>

			</DialogContent>

			<DialogActions className="justify-between border-t p-20">

				<Button onClick={() => { handleCancel(); }}>
					{t('CANCEL')}
				</Button>

				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					Add
				</Button>

			</DialogActions>

		</Dialog >
	);
}
export default AddMeasurementTaskDialog;
