import { authRoles } from 'app/auth';
import i18next from 'i18next';
import OutOfDistributionTable from './table/OutOfDistributionTable';
import en from './i18n/en';
import hu from './i18n/hu';
import OutOfDistributionEditForm from './editform/OutOfDistributionEditForm';

i18next.addResourceBundle('en', 'outOfDistribution', en);
i18next.addResourceBundle('hu', 'outOfDistribution', hu);

export default [
	{
		path: '/ood/edit/:type/:id/:tabKey?',
		component: OutOfDistributionEditForm,
		auth: authRoles.optimizer
	},
	{
		path: '/ood/:sessionNameParam?',
		component: OutOfDistributionTable,
		auth: authRoles.optimizer
	}
];
