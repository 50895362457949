import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import OutOfDistributionType from '../dto/OutOfDistributionType';
import OutOfDistributionEditService from '../service/OutOfDistributionEditService';
import AutoEncoderModelEditService from '../service/AutoEncoderModelEditService';
import ClassSvmModelEditService from '../service/ClassSvmModelEditService';
import IsolationForestModelEditService from '../service/IsolationForestModelEditService';
import LocalOutlierFactorModelEditService from '../service/LocalOutlierFactorModelEditService';

const outOfDistributionService = type => {
	if (type === 'AutoEncoder') return AutoEncoderModelEditService;
	if (type === 'ClassSvm') return ClassSvmModelEditService;
	if (type === 'IsolationForest') return IsolationForestModelEditService;
	if (type === 'LocalOutlierFactor') return LocalOutlierFactorModelEditService;
	return OutOfDistributionEditService;
};

export const shgoMethod = [
	{ label: 'BFGS', value: 'BFGS' },
	{ label: 'L-BFGS-B', value: 'L-BFGS-B' },
	{ label: 'SLSQP', value: 'SLSQP' },
];
export const shgoSampling = [
	{ label: 'Halton', value: 'halton' },
	{ label: 'Sobol', value: 'sobol' },
	{ label: 'Simplicial', value: 'simplicial' },
];

const config = data => {
	const form = {
		key: 'outOfDistributionEditForm',
		topic: 'outOfDistribution',
		service: outOfDistributionService(data.type),
		defaultValue: () => outOfDistributionService(data.type).createByType(data.type),
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'type',
					component: EditFormSelectField,
					type: 'enum',
					readOnly: true,
					items: Object.keys(OutOfDistributionType).map(key => {
						return { label: OutOfDistributionType[key], value: key };
					})
				},
				...(data.type === 'ParticleSwarm'
					? [
						{
							key: 'max_iteration_count',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'particle_count',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'w',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'c1',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'c2',
							component: EditFormTextField,
							type: 'number'
						}
					]
					: []),
				...(data.type === 'Shgo'
					? [
						{
							key: 'iters',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'n',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'method',
							component: EditFormSelectField,
							type: 'enum',
							items: shgoMethod
						},
						{
							key: 'sampling_method',
							component: EditFormSelectField,
							type: 'enum',
							items: shgoSampling
						},
						{
							key: 'workers',
							component: EditFormTextField,
							type: 'number'
						}
					]
					: []),
				...(data.type === 'DualAnnealing'
					? [
						{
							key: 'max_iter',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'initial_temp',
							component: EditFormTextField,
							type: 'number',
							min: 0.01
						},
						{
							key: 'restart_temp_ratio',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'visit',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'accept',
							component: EditFormTextField,
							type: 'number'
						}
					]
					: []),

				...(data.type === 'RandomRestart' || data.type === 'QuadraticRestart'
					? [
						{
							key: 'max_iteration_count',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'local_search_name',
							component: EditFormTextField
						}
					]
					: [])
			];
			return fields;
		}
	};

	return form;
};

function OutOfDistributionEditForm() {
	const { id, type, tabKey } = useParams();

	const myConfig = config({ id, type });
	console.log(myConfig);

	return <EditFormPage config={myConfig} />;
}

export default withRouter(OutOfDistributionEditForm);
