import userRouteConfig from './user/userRouteConfig';
import profileRouteConfig from './profile/profileRouteConfig';
import whatIfConfig from './whatIf/whatIfConfig';
import measurementUnitConfig from './measurementUnit/measurementUnitConfig';
import driverConfig from './driver/driverConfig';
import optimizerLogConfig from './optimizerLog/optimizerLogConfig';
import transformerConfig from './workflow/transformerConfig';
import modelConfig from './model/modelConfig';
import datasetConfig from './dataset/datasetConfig';
import modelAnnConfig from './modelAnn/modelAnnConfig';
import modelHysysConfig from './modelHysys/modelHysysConfig';
import optimizationCaseConfig from './optimizationCase/optimizationCaseConfig';
import modelLummusConfig from './modelLummus/modelLummusConfig';
import aspectConfig from './aspect/aspectConfig';
import driverLogConfig from './driverLog/driverLogConfig';
import modelExpressionConfig from './modelExpression/modelExpressionConfig';
import fittingCaseConfig from './fittingCase/fittingCaseConfig';
import taskManagerConfig from './taskManager/taskManagerConfig';
import fitterLogConfig from './fitterLog/fitterLogConfig';
import testConfig from './test/testConfig';
import optimizationPipelineConfig from './optimizationPipeline/optimizationPipelineConfig';
import pictogramConfig from './pictogram/pictogramConfig';
import piMeasurementReaderConfig from './piMeasurementReader/piMeasurementReaderConfig';
import modelWorkflowConfig from './modelWorkflow/modelWorkflowConfig';
import measurementConfig from './measurement/measurementConfig';
import schedulerConfig from './scheduler/schedulerConfig';
import modelLummus2Config from './modelLummus2/modelLummus2Config';
import modelOpcConfig from './modelOpc/modelOpcConfig';
import optimizerConfig from './optimizer/optimizerConfig';
import outOfDistributionConfig from './outOfDistribution/outOfDistributionConfig';
import unitOptimizerConfig from './unitOptimizer/unitOptimizerConfig';

const AdminConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		...userRouteConfig,
		...profileRouteConfig,
		...whatIfConfig,
		...measurementUnitConfig,
		...driverConfig,
		...optimizerLogConfig,
		...transformerConfig,
		...modelConfig,
		...datasetConfig,
		...modelAnnConfig,
		...modelHysysConfig,
		...modelLummusConfig,
		...modelLummus2Config,
		...modelOpcConfig,
		...optimizationCaseConfig,
		...aspectConfig,
		...driverLogConfig,
		...modelExpressionConfig,
		...fittingCaseConfig,
		...taskManagerConfig,
		...fitterLogConfig,
		...testConfig,
		...optimizationPipelineConfig,
		...pictogramConfig,
		...piMeasurementReaderConfig,
		...modelWorkflowConfig,
		...measurementConfig,
		...schedulerConfig,
		...optimizerConfig,
		...outOfDistributionConfig,
		...unitOptimizerConfig
	]
};

export default AdminConfig;
