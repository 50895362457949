import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import AspectService from 'app/admin/aspect/service/AspectService';
import AspectEditService from 'app/admin/aspect/service/AspectEditService';
import ModelService from 'app/admin/model/service/ModelService';
import { useWhatIf } from './WhatIfProvider';

export default function WhatIfLoadAspect() {
	const { t } = useTranslation('whatIf');
	const { data, setExperiment, stage } = useWhatIf();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	
	const schema = yup.object().shape({
		aspect: yup.number().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	const methods = useForm({
		defaultValues: { aspect: data && data.experiment && data.experiment.aspect ? data.experiment.aspect : null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleLoad = dto => {
		setLoading(true);
		setMessage(null);
		AspectEditService.getData(dto.aspect)
			.then(aspect => {
				if (aspect !== null && aspect.model !== null) {
					console.log(data);
					console.log(stage);
					console.log(aspect.model);
					console.log(aspect);
					console.log(AspectEditService.getImageByAspectId(aspect.id));
					ModelService.getData(aspect.model)
						.then(model => {
							try {
								changeExperiment(aspect, model);

								setLoading(false);
							} catch (e) {
								setLoading(false);
								setMessage(`Failed to load aspect: ${e}`);
							}
						})
						.catch(e => {
							setMessage(`Failed to load aspect: ${e}`);
							setLoading(false);
						});
				} else {
					setMessage(`Failed to load aspect: aspect not found`);
					setLoading(false);
				}
			})
			.catch(e => {
				setMessage(`Failed to load aspect: ${e}`);
				setLoading(false);
			});
	};

	const changeExperiment = (aspect, model) => {
		setExperiment(
			data.experiment
				? {
						...data.experiment,
						validity: false,
						aspect: aspect.id,
						attributes: [...(data.experiment.attributes ? data.experiment.attributes.filter(attr => attr.entity && attr.entity !== 'aspect') : []), ...parseAttributes(aspect, model)]
				  }
				: {
						id: null,
						name: null,
						aspect: aspect.id,
						transformer: null,
						whatIf: null,
						validity: true,
						attributes: parseAttributes(aspect, model)
				  }
		);
	};

	const parseAttributes = (aspect, model) => {
		const result = [];
		const modelInputAttributes = model.export ? model.export.input_attributes : model.input_attributes;
		const modelOutputAttributes = model.export ? model.export.output_attributes : model.output_attributes;
		if (aspect.input_attributes !== null && aspect.input_attributes.length > 0 && modelInputAttributes && modelInputAttributes.length > 0) {
			aspect.input_attributes.forEach(input => {
				const modelData = modelInputAttributes.find(d => d.name === (input.model_field_name != null ? input.model_field_name : input.name));
				if (modelData) {
					result.push({ ...input, io: 'INPUT', uom: modelData.uom, min_value: modelData.min_value, max_value: modelData.max_value, entity: 'aspect' });
				}
			});
		}
		if (aspect.output_attributes !== null && aspect.output_attributes.length > 0 && modelOutputAttributes && modelOutputAttributes.length > 0) {
			aspect.output_attributes.forEach(output => {
				const modelData = modelOutputAttributes.find(d => d.name === (output.model_field_name != null ? output.model_field_name : output.name));
				if (modelData) {
					result.push({ ...output, io: 'OUTPUT', uom: modelData.uom, type: modelData.type, value: modelData.value, entity: 'aspect' });
				}
			});
		}
		return result;
	};

	return (
		<FormProvider {...methods}>
			<div className="flex flex-row">
				<Controller
					control={methods.control}
					name="aspect"
					render={({ field, fieldState }) => (
						<EditFormSelectField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'whatIf' }}
							fieldConfig={{
								key: 'aspect',
								text: t('aspect'),
								items: () => AspectService.getListSelection()
							}}
						/>
					)}
				/>
				<div className="flex mx-8 mt-8 mb-16 items-center">
					<Button onClick={methods.handleSubmit(handleLoad)} disabled={loading} variant="contained" color="secondary">
						Load
					</Button>
				</div>
			</div>
			{message != null && <div>{message}</div>}
		</FormProvider>
	);
}
