import { authRoles } from 'app/auth';
import i18next from 'i18next';

import OptimizationPipeline from 'app/admin/optimizationPipeline/OptimizationPipeline';
import OptimizationPipelineProvider from 'app/admin/optimizationPipeline/component/OptimizationPipelineProvider';
import UnitOptimizerEditForm from './editform/UnitOptimizerEditForm';
import UnitOptimizerTable from './table/UnitOptimizerTable';

import en from './i18n/en';
import hu from './i18n/hu';

i18next.addResourceBundle('en', 'unitOptimizer', en);
i18next.addResourceBundle('hu', 'unitOptimizer', hu);

export default [
	// {
	// 	path: '/unit-optimizer/run/:id?',
	// 	component: () => (<OptimizationPipelineProvider><OptimizationPipeline /></OptimizationPipelineProvider>),
	// 	auth: authRoles.optimizer
	// },
	{
		path: '/unit-optimizer/edit/:id/:tabKey?',
		component: UnitOptimizerEditForm,
		auth: authRoles.optimizer
	},
	{
		path: '/unit-optimizer/:sessionNameParam?',
		component: UnitOptimizerTable,
		auth: authRoles.optimizer
	}
];
