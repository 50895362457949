import { useState, createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage as sliceShowMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import { generateId } from 'app/admin/whatIf/component/WhatIfUtil';
import _ from 'lodash';

const slideSensitivity = 20;
const jumpSensitivity = 25;
const cornerPercent = 25;

export const WorkflowContext = createContext(null);

export const useWorkflow = () => {
	return useContext(WorkflowContext);
};

export default function WorkflowProvider(props) {
	const { isTransformer = false } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation('transformer');
	const [stage, setStage] = useState(null);
	const [data, setData] = useState(null);
	const [editMode, setEditMode] = useState('MOVE');
	const [showPropertiesDialog, setShowPropertiesDialog] = useState(null);
	const [underEditing, setUnderEditing] = useState(null);
	const [selected, setSelected] = useState([]);
	const [selectedCalculate, setSelectedCalculate] = useState(null);
	const [floatCalculate, setFloatCalculate] = useState(null);
	const [floatValidation, setFloatValidation] = useState(null);
	const [calculate, setCalculate] = useState(null);

	// useEffect(() => {
	// 	console.log(data);
	// }, [data]);

	useEffect(() => {
		if (editMode !== 'MOVE' && editMode !== 'SELECT') setSelected([]);
	}, [editMode]);

	const checkStageCorner = () => {
		const mousePos = stage.getPointerPosition();
		const stageWidth = stage.width();
		const stageHeight = stage.height();
		const horizontalSensitivity = stageWidth * (cornerPercent / 100);
		const verticalSensitivity = stageHeight * (cornerPercent / 100);
		if ((mousePos.y <= slideSensitivity && mousePos.x <= horizontalSensitivity) || (mousePos.x <= slideSensitivity && mousePos.y <= verticalSensitivity)) {
			// TOPLEFT
			setData({ ...data, stage: { ...data.stage, x: data.stage.x + jumpSensitivity, y: data.stage.y + jumpSensitivity } });
		} else if ((mousePos.x >= stageWidth - horizontalSensitivity && mousePos.y <= slideSensitivity) || (mousePos.x >= stageWidth - slideSensitivity && mousePos.y <= verticalSensitivity)) {
			// TOPRIGHT
			setData({ ...data, stage: { ...data.stage, x: data.stage.x - jumpSensitivity, y: data.stage.y + jumpSensitivity } });
		} else if ((mousePos.x <= slideSensitivity && mousePos.y >= stageHeight - verticalSensitivity) || (mousePos.x <= horizontalSensitivity && mousePos.y >= stageHeight - slideSensitivity)) {
			// BOTTOMLEFT
			setData({ ...data, stage: { ...data.stage, x: data.stage.x + jumpSensitivity, y: data.stage.y - jumpSensitivity } });
		} else if ((mousePos.x >= stageWidth - horizontalSensitivity && mousePos.y >= stageHeight - slideSensitivity) || (mousePos.x >= stageWidth - slideSensitivity && mousePos.y >= stageHeight - verticalSensitivity)) {
			// BOTTOMRIGHT
			setData({ ...data, stage: { ...data.stage, x: data.stage.x - jumpSensitivity, y: data.stage.y - jumpSensitivity } });
		} else if (mousePos.x <= slideSensitivity) {
			// LEFT
			setData({ ...data, stage: { ...data.stage, x: data.stage.x + jumpSensitivity } });
		} else if (mousePos.x >= stageWidth - slideSensitivity) {
			// RIGHT
			setData({ ...data, stage: { ...data.stage, x: data.stage.x - jumpSensitivity } });
		} else if (mousePos.y <= slideSensitivity) {
			// TOP
			setData({ ...data, stage: { ...data.stage, y: data.stage.y + jumpSensitivity } });
		} else if (mousePos.y >= stageHeight - slideSensitivity) {
			// BOTTOM
			setData({ ...data, stage: { ...data.stage, y: data.stage.y - jumpSensitivity } });
		}
	};

	const initLine = (startKey, startValueKey, startPos) => {
		const newLine = {
			key: generateId(),
			start: { key: startKey, valueKey: startValueKey, pos: startPos },
			end: {
				key: null,
				valueKey: null,
				pos: startPos
			}
		};

		setData({
			...data,
			line: [...data.line, newLine]
		});
		setUnderEditing(newLine);
	};

	const drawLine = (x, y) => {
		const line = {
			...underEditing,
			end: { ...underEditing.end, pos: { x, y } }
		};
		setUnderEditing(line);
		setData({
			...data,
			line: data.line.map(d => {
				if (d.key === line.key) return line;
				return d;
			})
		});
	};

	const endLine = (itemKey, name, pointPos) => {
		if (data.line.findIndex(d => d.end.key === itemKey && d.end.valueKey === name) > -1) {
			showMessage(t('lineAlreadyConnected'));
			setData({
				...data,
				line: data.line.filter(d => d.key !== underEditing.key)
			});
			setUnderEditing(null);
		} else {
			const line = {
				...underEditing,
				end: { ...underEditing.end, key: itemKey, valueKey: name, pos: pointPos }
			};
			setUnderEditing(null);
			setData({
				...data,
				line: data.line.map(d => {
					if (d.key === line.key) return line;
					return d;
				})
			});
		}
	};

	/*
	const endLine = (itemKey, multipleEndInput) => {
		const { valueKey } = underEditing.start;
		if (
			data.line.findIndex(d => d.end.key === itemKey && d.end.valueKey === valueKey) > -1 ||
			data.transform.findIndex(d => d.key === itemKey && ((!multipleEndInput && d.inputs.length > 0) || (multipleEndInput && d.inputs.findIndex(inKey => inKey === valueKey) > -1))) > -1
		) {
			dispatch(showMessage({ message: t('lineAlreadyConnected') }));
			setData({
				...data,
				line: data.line.filter(d => d.key !== underEditing.key)
			});
			setUnderEditing(null);
		} else {
			const line = {
				...underEditing,
				end: { ...underEditing.end, key: itemKey, valueKey }
			};
			setUnderEditing(null);
			setData({
				...data,
				line: data.line.map(d => {
					if (d.key === line.key) return line;
					return d;
				}),
				transform: data.transform.map(d => {
					if (d.key === itemKey) {
						if (_.isUndefined(d.definition.input_keys))
							return {
								...d,
								inputs: [...d.inputs, valueKey],
								definition: {
									...d.definition,
									input_key: multipleEndInput ? d.definition.input_key : valueKey
								}
							};
						return {
							...d,
							inputs: [...d.inputs, valueKey],
							definition: {
								...d.definition,
								input_keys: [...d.inputs, valueKey]
							}
						};
					}
					return d;
				})
			});
		}
	};
*/
	const updateLine = (itemKey, valueKey, pos) => {
		setData({
			...data,
			line: data.line.map(d => {
				return {
					...d,
					start:
						d.start.key === itemKey && d.start.valueKey === valueKey
							? {
								...d.start,
								pos
							}
							: d.start,
					end:
						d.end.key === itemKey && d.end.valueKey === valueKey
							? {
								...d.end,
								pos
							}
							: d.end
				};
			})
		});
	};

	const updateLines = changedLines => {
		const _data = { ...data };
		console.log(_data);
		changedLines.forEach(_line => {
			_data.line.forEach(_l => {
				if (_l.start.key === _line.itemKey && _l.start.valueKey === _line.valueKey) {
					_l.start.pos = _line.pos;
				}
				if (_l.end.key === _line.itemKey && _l.end.valueKey === _line.valueKey) {
					_l.end.pos = _line.pos;
				}
			});
		});
		setData(_data);
	};

	const deleteLine = lineKey => {
		setData({
			...data,
			line: data.line.filter(d => d.key !== lineKey)
		});
	};

	const valueKeyAvailable = (valueKey, itemKey) => {
		let found = false;
		data.value.forEach(d => {
			if ((itemKey == null || d.key !== itemKey) && valueKey === d.valueKey) found = true;
		});
		return !found;
	};

	const valueKeysAvailable = (valueList, itemKey) => {
		let found = false;
		data.value.forEach(d => {
			if (d.key !== itemKey && valueList.indexOf(d.valueKey) > -1) found = true;
		});
		data.transform.forEach(d => {
			if (d.key !== itemKey) {
				d.outputs.forEach(output => {
					if (valueList.indexOf(output) > -1) found = true;
				});
			}
		});
		return !found;
	};

	const outputKeyNameChanged = (_data, oldName, newName) => {
		setData({
			..._data,
			line: _data.line.map(l => {
				if (l.start.valueKey === oldName) {
					return {
						...l,
						start: {
							...l.start,
							valueKey: newName
						},
						end: {
							...l.end,
							valueKey: newName
						}
					};
				}
				return l;
			}),
			transform: _data.transform.map(tr => {
				if (tr.inputs.findIndex(input => input === oldName) > -1) {
					return {
						...tr,
						inputs: tr.inputs.map(input => (input === oldName ? newName : input))
					};
				}
				if (tr.definition.input_key && tr.definition.input_key === oldName) {
					return {
						...tr,
						definition: { ...tr.definition, input_key: newName }
					};
				}
				if (tr.definition.input_keys && tr.definition.input_keys.findIndex(input => input === oldName) > -1) {
					return {
						...tr,
						definition: { ...tr.definition, input_keys: tr.definition.input_keys.map(input => (input === oldName ? newName : input)) }
					};
				}
				return tr;
			})
		});
	};

	const transformationNameAvailable = (valueKey, itemKey) => {
		return data.transform.findIndex(d => d.key !== itemKey && d.name === valueKey) === -1;
	};

	const updateTransformationProperties = (item, value) => {
		if (value.new) delete value.new;
		const inputFieldMap = {};
		const outputFieldMap = {};
		const oldInputList = item.input_fields.map(d => d.name);
		const newInputList = value.input_fields.map(d => d.name);
		if (newInputList.length === oldInputList.length) {
			oldInputList.forEach((oldKey, idx) => {
				inputFieldMap[oldKey] = newInputList[idx];
			});
		} else {
			oldInputList.forEach((oldKey, idx) => {
				if (newInputList.indexOf(oldKey)) inputFieldMap[oldKey] = oldKey;
			});
		}
		const oldOutputList = item.output_fields.map(d => d.name);
		const newOutputList = value.output_fields.map(d => d.name);
		if (newOutputList.length === oldOutputList.length) {
			oldOutputList.forEach((oldKey, idx) => {
				outputFieldMap[oldKey] = newOutputList[idx];
			});
		} else {
			oldOutputList.forEach((oldKey, idx) => {
				if (newOutputList.indexOf(oldKey)) outputFieldMap[oldKey] = oldKey;
			});
		}
		const newLines = data.line
			.filter(line => {
				if (line.end.key === item.key && _.isUndefined(inputFieldMap[line.end.valueKey])) return false;
				if (line.start.key === item.key && _.isUndefined(outputFieldMap[line.start.valueKey])) return false;
				return true;
			})
			.map(line => {
				if (line.end.key === item.key) return { ...line, end: { ...line.end, valueKey: inputFieldMap[line.end.valueKey] } };
				if (line.start.key === item.key) return { ...line, start: { ...line.start, valueKey: outputFieldMap[line.start.valueKey] } };
				return line;
			});

		setData({
			...data,
			line: newLines,
			transform: data.transform.map(c => (c.key === item.key ? value : c))
		});
	};

	const updateIOProperties = (item, value) => {
		if (value.new) delete value.new;
		if (item.io !== value.io) {
			setData({
				...data,
				line: data.line.filter(line => {
					if (line.end.key === item.key) return false;
					if (line.start.key === item.key) return false;
					return true;
				}),
				value: data.value.map(c => (c.key === item.key ? value : c))
			});
		} else if (item.valueKey !== value.valueKey) {
			setData({
				...data,
				line: data.line.map(line => {
					if (item.io === 'INPUT' && line.start.key === item.key) return { ...line, start: { ...line.start, valueKey: value.valueKey } };
					if (item.io === 'OUTPUT' && line.end.key === item.key) return { ...line, start: { ...line.start, valueKey: value.valueKey } };
					return line;
				}),
				value: data.value.map(c => (c.key === item.key ? value : c))
			});
		} else {
			setData({
				...data,
				value: data.value.map(c => (c.key === item.key ? value : c))
			});
		}
	};

	const deleteNewCanceledTransformation = item => {
		if (item.new) {
			setData({
				...data,
				transform: data.transform.filter(d => d.key !== item.key)
			});
		}
	};

	const showMessage = message => {
		dispatch(sliceShowMessage({ message }));
	};

	const value = {
		stage,
		setStage,
		data,
		setData,
		editMode,
		setEditMode,
		showPropertiesDialog,
		setShowPropertiesDialog,
		checkStageCorner,
		underEditing,
		setUnderEditing,
		initLine,
		drawLine,
		endLine,
		updateLine,
		updateLines,
		deleteLine,
		valueKeyAvailable,
		valueKeysAvailable,
		outputKeyNameChanged,
		selected,
		setSelected,
		calculate,
		setCalculate,
		selectedCalculate,
		setSelectedCalculate,
		floatCalculate,
		setFloatCalculate,
		isTransformer,
		transformationNameAvailable,
		updateTransformationProperties,
		updateIOProperties,
		deleteNewCanceledTransformation,
		floatValidation,
		setFloatValidation,
		showMessage
	};

	return <WorkflowContext.Provider value={value}>{props.children}</WorkflowContext.Provider>;
}
