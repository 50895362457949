const optimizationPipelineEn = {
	optimizationPipeline: 'Optimiztation Pipeline',
	aspectEditForm: 'Optimiztation Pipeline',
	name: 'Name',
	description: 'Description',
	driver: 'Driver',
	model: 'Model',
	dataset: 'Dataset',
	basic: 'Basic',
	input_attributes: 'Input Attributes',
	output_attributes: 'Output Attributes',
	type: 'Type',
	value: 'Value',
	attribute_class: 'Attribute class',
	model_field_name: 'Model field name',
	state: 'State',
	values: 'Values',
	reader: 'Reader',
	summary_type: 'Summary type',
	summary_duration: 'Summary duration',
	summary_duration_type: 'Summary duration type'
};

export default optimizationPipelineEn;
