import { authRoles } from 'app/auth';
import i18next from 'i18next';
import enNavigation from './navigation-i18n/en';
import huNavigation from './navigation-i18n/hu';

i18next.addResourceBundle('en', 'navigation', enNavigation);
i18next.addResourceBundle('hu', 'navigation', huNavigation);

const navigationConfig = [
	{
		id: 'whatIf',
		title: 'Operator',
		translate: 'OPERATOR',
		type: 'group',
		icon: 'apps',
		auth: [authRoles.whatif],
		children: [
			{
				id: 'run',
				title: 'What If',
				translate: 'WHATIF',
				type: 'item',
				icon: 'play_arrow',
				url: '/what-if',
				auth: authRoles.whatif
			}
		]
	},
	{
		id: 'expert',
		title: 'Expert',
		translate: 'EXPERT',
		type: 'group',
		icon: 'apps',
		auth: [
			authRoles.optimizationCase,
			authRoles.optimizer,
			authRoles.optimizerLog,
			authRoles.dataset,
			authRoles.aspect,
			authRoles.whatifExpert,
			authRoles.whatifGroup,
			authRoles.fittingCase,
			authRoles.taskManager,
			authRoles.fitterLog,
			authRoles.transformer,
			authRoles.measurement,
			authRoles.scheduler
		],
		children: [
			{
				id: 'taskManager',
				title: 'Task Manager',
				translate: 'TASKMANAGER',
				type: 'item',
				icon: 'reorder',
				url: '/task-manager',
				auth: authRoles.taskManager
			},
			{
				id: 'optimizationPipeline',
				title: 'Optimization Pipeline',
				translate: 'OPTIMIZATIONPIPELINE',
				type: 'item',
				icon: 'reorder',
				url: '/optimization-pipeline',
				auth: authRoles.taskManager
			},
			{
				id: 'unitOptimizer',
				title: 'Unit Optimizer',
				translate: 'UNITOPTIMIZER',
				type: 'item',
				icon: 'reorder',
				url: '/unit-optimizer',
				auth: authRoles.taskManager
			},
			{
				id: 'scheduler',
				title: 'Scheduler',
				translate: 'SCHEDULER',
				type: 'item',
				icon: 'reorder',
				url: '/scheduler',
				auth: authRoles.scheduler
			},
			{
				id: 'fittingCase',
				title: 'Fitting Case',
				translate: 'FITTINGCASE',
				type: 'item',
				icon: 'reorder',
				url: '/fitting-case',
				auth: authRoles.fittingCase
			},
			{
				id: 'fitterLog',
				title: 'Fitter Log',
				translate: 'FITTERLOG',
				type: 'item',
				icon: 'reorder',
				url: '/fitter-log',
				auth: authRoles.fitterLog
			},
			{
				id: 'optimizationCase',
				title: 'Optimization Case',
				translate: 'OPTIMIZATIONCASE',
				type: 'item',
				icon: 'reorder',
				url: '/optimization-case',
				auth: authRoles.optimizationCase
			},
			{
				id: 'optimizer',
				title: 'Optimizer',
				translate: 'OPTIMIZER',
				type: 'item',
				icon: 'reorder',
				url: '/optimizer',
				auth: authRoles.optimizer
			},		
			{
				id: 'optimizerLog',
				title: 'Optimizer Log',
				translate: 'OPTIMIZERLOG',
				type: 'item',
				icon: 'reorder',
				url: '/optimizer-log',
				auth: authRoles.optimizerLog
			},
			{
				id: 'outOfDistribution',
				title: 'Out Of Distribution',
				translate: 'OUTOFDISTRIBUTION',
				type: 'item',
				icon: 'reorder',
				url: '/ood',
				auth: authRoles.optimizer
			},
			{
				id: 'whatifExpert',
				title: 'What-If',
				translate: 'WHATIF',
				type: 'item',
				icon: 'reorder',
				url: '/what-if-expert',
				auth: authRoles.whatifExpert
			},
			{
				id: 'whatifGroup',
				title: 'What-If group',
				translate: 'WHATIFGROUP',
				type: 'item',
				icon: 'reorder',
				url: '/what-if-group',
				auth: authRoles.whatifGroup
			},
			{
				id: 'transformer',
				title: 'Transformer',
				translate: 'TRANSFORMER',
				type: 'item',
				icon: 'reorder',
				url: '/transformer',
				auth: authRoles.transformer
			},
			{
				id: 'aspect',
				title: 'Aspect',
				translate: 'ASPECT',
				type: 'item',
				icon: 'reorder',
				url: '/aspect',
				auth: authRoles.aspect
			},
			{
				id: 'dataset',
				title: 'Dataset',
				translate: 'DATASET',
				type: 'item',
				icon: 'reorder',
				url: '/dataset',
				auth: authRoles.dataset
			},
			{
				id: 'measurement',
				title: 'Measurement',
				translate: 'MEASUREMENT',
				type: 'item',
				icon: 'reorder',
				url: '/measurement',
				auth: authRoles.measurement
			}
		]
	},
	{
		id: 'tech',
		title: 'Tech',
		translate: 'TECH',
		type: 'group',
		icon: 'apps',
		auth: [authRoles.measurementUnit, authRoles.driver, authRoles.model, authRoles.pictogram, authRoles.piMeasurementReader],
		children: [
			{
				id: 'model',
				title: 'Model',
				translate: 'MODEL',
				type: 'item',
				icon: 'change_history',
				url: '/model',
				auth: authRoles.model
			},
			{
				id: 'driver',
				title: 'Driver',
				translate: 'DRIVER',
				type: 'item',
				icon: 'device_hub',
				url: '/driver',
				auth: authRoles.measurementUnit
			},
			{
				id: 'driverLog',
				title: 'Driver Log',
				translate: 'DRIVERLOG',
				type: 'item',
				icon: 'timeline',
				url: '/driver-log',
				auth: authRoles.driverLog
			},
			{
				id: 'piMeasurementReader',
				title: 'PI Measurement Reader',
				translate: 'PI_MEASUREMENT_READER',
				type: 'item',
				icon: 'category',
				url: '/pi-measurement-reader',
				auth: authRoles.piMeasurementReader
			},
			{
				id: 'measurementUnit',
				title: 'Mértékegység',
				translate: 'MEASUREMENT_UNIT',
				type: 'item',
				icon: 'category',
				url: '/measurement-unit',
				auth: authRoles.measurementUnit
			},
			{
				id: 'pictogram',
				title: 'Pictogram',
				translate: 'PICTOGRAM',
				type: 'item',
				icon: 'image',
				url: '/pictogram',
				auth: authRoles.pictogram
			}
		]
	},
	{
		id: 'users',
		title: 'users',
		translate: 'USER',
		type: 'group',
		icon: 'apps',
		auth: [authRoles.userAccount, authRoles.userRole],
		children: [
			{
				id: 'user-list',
				title: 'Hozzáférés',
				translate: 'USER_LIST',
				type: 'item',
				icon: 'person',
				url: '/user',
				auth: authRoles.userAccount
			},
			{
				id: 'dashboard-role',
				title: 'Jogkör',
				translate: 'DASHBOARD_ROLE',
				type: 'item',
				icon: 'perm_identity',
				url: '/dashboard-role',
				auth: authRoles.userRole
			}
		]
	}
];

export default navigationConfig;
