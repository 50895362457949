import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import OutOfDistributionTableService from '../service/OutOfDistributionTableService';
import AddOutOfDistributionButton from '../component/AddOutOfDistributionButton';

const config = data => {
	return {
		key: 'outOfDistributionTable',
		topic: 'outOfDistribution',
		service: OutOfDistributionTableService,
		editFormState: item => `/ood/edit/${item.type}/${item ? item.id : 'new'}`,
		toolbarButtonList: [
			{
				key: 'addNew',
				place: 'before',
				component: AddOutOfDistributionButton
			}
		],
		toolbarAction: ['search', 'refresh'],
		rowAction: ['modify', 'delete'],
		columns: [
			{
				key: 'name'
			},
			{
				key: 'type'
			}
		]
	};
};

function OutOfDistributionTable() {
	const myConfig = config();
	return <TableBrowser config={myConfig} />;
}

export default withReducer('outOfDistributionTable', reducer('outOfDistributionTable'))(OutOfDistributionTable);
