import { useState, useEffect } from 'react';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import { useParams, withRouter } from 'react-router';
import UnitOptimizerEditService from '../service/UnitOptimizerEditService';

const config = data => {
	const form = {
		key: 'unitOptimizerEditForm',
		topic: 'unitOptimizer',
		service: UnitOptimizerEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'unitOptimizerRules' }];
		},
		// defaultValue: () => outOfDistributionService(data.type).createByType(data.type),
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'unit',
					component: EditFormTextField,
					required: false
				},
				{
					key: 'piMeasurementReader',
					component: EditFormSelectField,
					type: 'enum',
					items: data.formData.pi_measurement_reader.map(item => {
						return { label: item.name, value: item.id };
					})
				},
				{
					key: 'rawMeasurement',
					component: EditFormSelectField,
					type: 'enum',
					items: data.formData.measurement.map(item => {
						return { label: item.name, value: item.id };
					})
				},
				{
					key: 'cleansedMeasurement',
					component: EditFormSelectField,
					type: 'enum',
					items: data.formData.measurement.map(item => {
						return { label: item.name, value: item.id };
					})
				},
				{
					key: 'oodModel',
					component: EditFormSelectField,
					type: 'enum',
					items: data.formData.ood_model.map(item => {
						return { label: item.name, value: item.id };
					})
				},
				{
					key: 'accuracyThreshold',
					component: EditFormTextField,
					type: 'number',
					required: false
				},
				{
					key: 'aspect',
					component: EditFormSelectField,
					type: 'enum',
					items: data.formData.aspect.map(item => {
						return { label: item.name, value: item.id };
					})
				},
				{
					key: 'dataset',
					component: EditFormSelectField,
					type: 'enum',
					items: data.formData.dataset.map(item => {
						return { label: item.name, value: item.id };
					})
				},
				{
					key: 'optimizationCase',
					component: EditFormSelectField,
					type: 'enum',
					items: data.formData.optimization_case.map(item => {
						return { label: item.name, value: item.id };
					})
				},
				{
					key: 'unitOptimizerRules',
					tab: 'unitOptimizerRules',
					component: EditFormGridComponent,
					// defaultValue: {
					// 	id: null,
					// 	name: null,
					// 	domain_from: null,
					// 	domain_to: null,
					// 	step_count: null
					// },
					fields: [
						{
							key: 'min_value',
							component: EditFormTextField,
							type: 'number',
							required: true
						},
						{
							key: 'max_value',
							component: EditFormTextField,
							type: 'number',
							required: true
						},
					]
				}
			];
			return fields;
		}
	};

	return form;
};

function UnitOptimizerEditForm() {
	const { id } = useParams();
	// const dispatch = useDispatch();
	const [formData, setFormData] = useState(null);
	if (formData == null) {
		UnitOptimizerEditService.getFormData().then(resp => {
			setFormData(resp);
		});
		return <></>;
	}
	const myConfig = config({ id, formData });

	return <EditFormPage config={myConfig} />;
}

export default withRouter(UnitOptimizerEditForm);
