import React, { useState } from 'react';
import { IconButton, Tooltip, TableCell } from '@material-ui/core';
import ShowChart from '@material-ui/icons/ShowChart';
import ChartPopup from 'app/admin/common/component/ChartPopup';

function ShowChartButton(props) {
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <TableCell className='w-auto text-center' padding='none'>

            <Tooltip title='Show Chart'>
                <span>
                    <IconButton onClick={e => { setOpenDialog(true); e.preventDefault(); }}>
                        <ShowChart fontSize='small' />
                    </IconButton>
                </span>
            </Tooltip>

            {openDialog && (
                <ChartPopup name={props.item.name} onClose={() => setOpenDialog(false)} />
            )}

        </TableCell>
    );
}

export default ShowChartButton;
