import DriverDto from 'app/admin/driver/dto/DriverDto';
import NamedDto from 'modules/base/dto/NamedDto';

export class UnitOptimizerTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
	}

	/**
	 * @typedef {Object} _UnitOptimizerTableDtoProps
	 * @property {string} type
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _UnitOptimizerTableDtoProps} UnitOptimizerTableDtoProps
	 */
	/**
	 *
	 * @param {UnitOptimizerTableDtoProps} props
	 * @returns {UnitOptimizerTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		return this;
	}
}

export default UnitOptimizerTableDto;
