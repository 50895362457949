import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	root: {
		'& .logo-icon': {
			transition: theme.transitions.create(['width', 'height'], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		},
		'& .react-badge, & .logo-text': {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		}
	},
	reactBadge: {
		backgroundColor: '#121212',
		color: '#61DAFB'
	}
}));

function Logo() {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, 'flex items-center')}>

			<img className="logo-icon w-44" src="assets/images/logos/mol_logo.svg" alt="logo" />

			<Typography className="text-16 leading-tight mx-14 logo-text" color="inherit">
				<div className='font-semibold'>Digital Twin</div>
				<div className='text-10 tracking-wider opacity-40'>Dashboard</div>
			</Typography>

		</div>
	);
}

export default Logo;
