import _ from '@lodash';
import { Checkbox, TableCell } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import TaskManagerService from 'app/admin/taskManager/service/TaskManagerService';

function TaskManagerEnabledButton(props) {
	const { item } = props;
	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		setEnabled(item.enabled);
	}, [item]);

	const handleChange = e => {
		TaskManagerService.updateEnabled(props.item.name, e.target.checked).then(resp => {
			setEnabled(!e.target.checked);
		});
	};

	return (
		<TableCell className="w-auto text-center" padding="none">

			<Checkbox checked={enabled} onChange={handleChange}/*indeterminate={inputSelected.length > 0 && inputSelected.length < inputs.length}*/ />

		</TableCell>
	);
}

export default withRouter(TaskManagerEnabledButton);