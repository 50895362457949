import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import StorageDriverService from 'app/admin/driver/service/StorageDriverService';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import EditFormFreeSoloField from 'modules/ui/editform/fields/EditFormFreeSoloField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormComponentMassSomethingField from 'app/admin/common/component/EditFormComponentMassSomethingField';
import { useParams, withRouter } from 'react-router';
import ModelCalculate from 'app/admin/common/component/ModelCalculate';
import AnnModelEditService from '../service/AnnModelEditService';

const config = data => {
	const storageDriverService = new StorageDriverService();
	return {
		key: 'annModelEditForm',
		topic: 'model',
		service: AnnModelEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'input_attributes' }, { key: 'output_attributes' }];
		},
		backLink: () => '/model',
		defaultValue: () => AnnModelEditService.create(data.driverName),
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'driver',
					component: EditFormSelectField,
					required: true,
					items: () => storageDriverService.getListSelectionByDriverType('ann')
				},
				{
					key: 'model_zip_file',
					component: EditFormFileInputField,
					required: true
				},
				{
					key: 'xscaler_file',
					component: EditFormFileInputField,
					required: true
				},
				{
					key: 'yscaler_file',
					component: EditFormFileInputField,
					required: true
				},
				{
					key: 'state_file',
					component: EditFormFileInputField,
					required: true
				},
				{
					key: 'image',
					component: EditFormFileInputField,
					required: true
				},
				{
					key: 'input_attributes',
					tab: 'input_attributes',
					component: EditFormGridComponent,
					orderable: true,
					defaultValue: {
						id: null,
						name: null,
						type: null,
						value: null,
						min_value: null,
						max_value: null,
						uom: null,
						orderIndex: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: [
								{ label: 'Variable', value: 'Variable' },
								{ label: 'ComponentMassFrac', value: 'ComponentMassFrac' },
								{ label: 'DistillationCurve', value: 'DistillationCurve' }
							]
						},
						{
							key: 'value',
							component: EditFormTextField,
							required: true,
							type: 'number',
							dependsOn: 'type',
							visibleIf: type => type === 'Variable'
						},
						{
							key: 'value',
							component: EditFormComponentMassSomethingField,
							dependsOn: 'type',
							type: 'MIN_MAX_UOM',
							disableCopyPaste: true,
							visibleIf: type => (type === 'ComponentMassFrac' || type === 'DistillationCurve')
						},
						{
							key: 'min_value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'min_value',
							component: EditFormComponentMassSomethingField,
							loadValueFrom: 'value',
							loadValueMultiplier: 0.9,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						},
						{
							key: 'max_value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'max_value',
							component: EditFormComponentMassSomethingField,
							loadValueFrom: 'value',
							loadValueMultiplier: 1.1,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						},
						{
							key: 'uom',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable'
						}
					]
				},
				{
					key: 'output_attributes',
					tab: 'output_attributes',
					component: EditFormGridComponent,
					orderable: true,
					defaultValue: {
						id: null,
						name: null,
						type: null,
						uom: null,
						orderIndex: null,
						components: []
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: [
								{ label: 'Variable', value: 'Variable' },
								{ label: 'ComponentMassFrac', value: 'ComponentMassFrac' },
								{ label: 'DistillationCurve', value: 'DistillationCurve' }
							]
						},
						{
							key: 'components',
							component: EditFormFreeSoloField,
							required: true,
							multiple: true,
							query: () => new Promise(resolve => resolve([])),
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac'
						},
						{
							key: 'uom',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable'
						}
					]
				}
			];

			return fields;
		}
	};
};

function AnnModelEditForm() {
	const { id, driverName } = useParams();
	const dispatch = useDispatch();

	const myConfig = config({ id, driverName });
	return (
		<EditFormPage
			config={myConfig}
			beforeSaveContent={<ModelCalculate id={id} type="model" />}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(AnnModelEditForm);
