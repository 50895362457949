import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export default class TaskManagerTableDto extends NamedDto {
	constructor() {
		super();

		/** @type {string} */
		this.user_name = null;

		/** @type {string} */
		this.schedule = null;

		/** @type {boolean} */
		this.enabled = null;

		/** @type {string} */
		this.type = null;

		/** @type {number} */
		this.file_count = null;

		/** @type {number} */
		this.ok_count = null;

		/** @type {number} */
		this.fail_count = null;

		/** @type {Date} */
		this.last_started = null;

		/** @type {string} */
		this.last_status = null;

		/** @type {Date} */
		this.last_timestamp = null;

		/** @type {string} */
		this.last_message = null;

		/** @type {string} */
		this.last_result = null;
	}

	/**
	 * @typedef {Object} _TaskManagerTableDtoProps
	 * @property {string} user_name
	 * @property {string} schedule
	 * @property {boolean} enabled
	 * @property {string} type
	 * @property {number} file_count
	 * @property {number} ok_count
	 * @property {number} fail_count
	 * @property {Date} last_started
	 * @property {string} last_status
	 * @property {Date} last_timestamp
	 * @property {string} last_message
	 * @property {string} last_result
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _TaskManagerTableDtoProps} TaskManagerTableDtoProps
	 */
	/**
	 *
	 * @param {TaskManagerTableDtoProps} props
	 * @returns {TaskManagerTableDto}
	 */
	setup(props) {
		super.setup(props);

		this.user_name = props.user_name;
		this.schedule = props.schedule;
		this.enabled = props.enabled;
		this.type = props.type;
		this.file_count = props.file_count;
		this.ok_count = props.ok_count;
		this.fail_count = props.fail_count;
		this.last_started = props.last_started !== null ? new Date(props.last_started) : null;
		this.last_status = props.last_status;
		this.last_timestamp = props.last_timestamp !== null ? new Date(props.last_timestamp) : null;
		this.last_message = props.last_message;
		this.last_result = props.last_result;
		
		return this;
	}

	getAsString(key) {
		return super.getAsString(key);
	}
}
