import React, { useState } from 'react';
import PlayArrow from '@material-ui/icons/PlayArrow';
import AddMeasurementTaskDialog from 'app/admin/common/component/AddMeasurementTaskDialog';
import { IconButton, Tooltip, TableCell } from '@material-ui/core';

function AddMeasurementTaskButton(props) {
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <TableCell className='w-auto text-center' padding='none'>

            <Tooltip title='Add Task Manager'>
                <span>
                    <IconButton onClick={e => { setOpenDialog(true); e.preventDefault(); }}>
                        <PlayArrow fontSize='small' />
                    </IconButton>
                </span>
            </Tooltip>

            {openDialog && (
                <AddMeasurementTaskDialog
                    readerId={props.config.key === 'piMeasurementReaderTable' ? props.item.id : null}
                    measurementId={props.config.key === 'measurementTable' ? props.item.id : null}
                    onCancel={() => setOpenDialog(false)}
                    onSave={result => {
                        setOpenDialog(false);
                    }}
                />
            )}

        </TableCell>
    );
}

export default AddMeasurementTaskButton;