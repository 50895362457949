const commonEn = {
	YES: 'Yes',
	NO: 'No',
	CANCEL: 'Cancel',
	SAVE: 'Save',
	LOADING: 'Loading...',
	close: 'Close',
	load_from_value: 'Load from value',
	copy: 'Copy',
	to_clipboard: 'Copy to Clipboard',
	to_clipboard_short: 'Copy',
	to_clipboard_successful: 'Data copied to clipboard',
	to_clipboard_failed: 'Error copying to clipboard',
	from_clipboard: 'Paste from Clipboard',
	from_clipboard_short: 'Paste',
	from_clipboard_successful: 'Data successfully pasted from clipboard',
	from_clipboard_failed: 'Paste from clipboard failed, please check the data',
	notifications: 'Notifications',
	delete_all: 'Delete all',
	emptyNotifications: 'There are no notifications for now.'
};

export default commonEn;
