const navigationHu = {
	USER: 'FELHASZNÁLÓ',
	USER_LIST: 'Hozzáférés',
	DASHBOARD_ROLE: 'Jogkör',
	TECH: 'Tech',
	MEASUREMENT_UNIT: 'Mértékegység',
	WHATIF: 'What if',
	OPTIMIZATIONPIPELINE: 'Optimization Pipeline',
	WHATIFRUN: 'Run',
	WHATIFEXPERT: 'What if',
	WHATIFGROUP: 'What if group',
	DRIVER: 'Driver',
	DRIVERLOG: 'Driver Log',
	EXPERT: 'Expert',
	OPTIMIZERLOG: 'Optimizer Log',
	DATASET: 'Dataset',
	MODEL: 'Model',
	OPTIMIZATIONCASE: 'Optimization Case',
	OBJECTIVE: 'Objective',
	ASPECT: 'Aspect',
	OPERATOR: 'Operator',
	WORKFLOW: 'Workflow',
	FITTINGCASE: 'Fitting Case',
	TASKMANAGER: 'Task Manager',
	FITTERLOG: 'Fitter Log',
	PICTOGRAM: 'Pictogram',
	PI_MEASUREMENT_READER: 'PI Measurement Reader',
	TRANSFORMER: 'Transformer',
	MEASUREMENT: 'Measurement',
	SCHEDULER: 'Scheduler',
	OPTIMIZER: 'Optimizer',
	OUTOFDISTRIBUTION: 'Out Of Distribution',
	UNITOPTIMIZER: 'Unit Optimizer'
};

export default navigationHu;
