import _ from '@lodash';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';

import PiMeasurementReaderService from 'app/admin/piMeasurementReader/service/PiMeasurementReaderService';

export function AddMeasurementDialog(props) {
    const history = useHistory();
    const { t } = useTranslation(['common']);
    const dispatch = useDispatch();

    const schema = yup.object().shape({
        // readerId: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
        measurementId: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
    });

    const getDefaultValues = () => {
        let defaultValues = {};
        // if (props.readerId) defaultValues.readerId = props.readerId;
        return defaultValues;
    };

    const methods = useForm({
        defaultValues: getDefaultValues(),
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    const handleSave = value => {
        PiMeasurementReaderService.createMeasurement(value.measurementId/*, value.readerId*/,props.readerName).then(response => handleResponse(response)).catch(err => handleCatch(err));
    };

    const handleResponse = (response) => {
        if (_.isNumber(response)) {
            history.push(`/measurement`);
        }
        dispatch(showMessage({ message: 'New Measurement successfully added' }));
    };

    const handleCatch = (error) => {
        console.log(error);
        dispatch(showMessage({ message: 'Error while creating new Measurement!' }));
    };

    const handleCancel = () => {
        props.onCancel();
    };

    return (
        <Dialog open fullWidth>

            <DialogTitle disableTypography className="border-b">
                Add Measurement
            </DialogTitle>

            <DialogContent className="p-20">

                <FormProvider {...methods}>
                    {/* <Controller
                        control={methods.control}
                        name="readerId"
                        render={({ field, fieldState }) => (
                            <EditFormSelectField
                                field={field}
                                fieldState={fieldState}
                                config={{ topic: 'common' }}
                                fieldConfig={{
                                    key: 'readerId',
                                    text: 'Reader',
                                    required: true,
                                    items: () => PiMeasurementReaderService.getListSelection()
                                }}
                            />
                        )}
                    /> */}
                    <Controller
                        control={methods.control}
                        name="measurementId"
                        render={({ field, fieldState }) => (
                            <EditFormTextField
                                field={field}
                                fieldState={fieldState}
                                config={{ topic: 'common' }}
                                fieldConfig={{
                                    key: 'measurementName',
                                    text: 'Measurement Name',
                                    required: true
                                }}
                            />
                        )}
                    />
                </FormProvider>

            </DialogContent>

            <DialogActions className="justify-between border-t p-20">

                <Button onClick={() => { handleCancel(); }}>
                    {t('CANCEL')}
                </Button>

                <Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
                    Add
                </Button>

            </DialogActions>

        </Dialog >
    );
}
export default AddMeasurementDialog;
