const unitOptimizerEn = {
	unitOptimizerTable: 'Unit Optimizer',
	unitOptimizerEditForm: 'Unit Optimizer',
	name: 'Name',
	unit: 'Unit',
	piMeasurementReader: 'Pi Measurement Reader',
	rawMeasurement: 'Raw Measurement',
	cleansedMeasurement: 'Cleansed Measurement',
	oodModel: 'Out of Distribution Model',
	accuracyThreshold: 'Accuracy Threshold',
	aspect: 'Aspect',
	dataset: 'Dataset',
	optimizationCase: 'Optimization Case',
	basic: 'Basic',
	unitOptimizerRules: 'Unit Optimizer Rules',
	min_value: 'Minimum Value',
	max_value: 'Maximum Value',
};

export default unitOptimizerEn;
