import { useState } from 'react';
import { Button, Icon, Menu, MenuItem, ThemeProvider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import OutOfDistributionType from '../dto/OutOfDistributionType';

function AddOutOfDistributionButton() {
	const mainTheme = useSelector(selectMainTheme);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className="whitespace-nowrap mx-8" variant="contained" color="secondary" startIcon={<Icon className="hidden sm:flex">add</Icon>}>
				Add new
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					{Object.keys(OutOfDistributionType).map((opt, i) => (
						<MenuItem key={i} component={Link} to={`/ood/edit/${opt}/new`} role="button">
							{OutOfDistributionType[opt]}
						</MenuItem>
					))}
				</Menu>
			</ThemeProvider>
		</>
	);
}

export default AddOutOfDistributionButton;
