import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import UnitOptimizerTableService from '../service/UnitOptimizerTableService';
import RunUnitOptimizerButton from '../component/RunUnitOptimizerButton';

const config = data => {
	return {
		key: 'unitOptimizerTable',
		topic: 'unitOptimizer',
		service: UnitOptimizerTableService,
		editFormState: item => `/unit-optimizer/edit/${item ? item.id : 'new'}`,
		toolbarAction: ['search', 'refresh', 'new'],
		rowAction: ['modify', 'delete'],
		// rowButtonList: [{ key: 'runUnitOptimzer', component: RunUnitOptimizerButton }],
		columns: [
			{
				key: 'name'
			}
		]
	};
};

function UnitOptimizerTable() {
	const myConfig = config();
	return <TableBrowser config={myConfig} />;
}

export default withReducer('unitOptimizerTable', reducer('unitOptimizerTable'))(UnitOptimizerTable);
