import _ from '@lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import { BsFiletypeJson, BsFiletypeXls } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';

export function ComponentMassSomethingDialog(props) {
	const { loadValueFrom, loadValueMultiplier, fieldPrefix, disableCopyPaste } = props;
	const { t } = useTranslation(['common']);
	const { getValues } = useFormContext();
	const dispatch = useDispatch();
	const [copyConfirm, setCopyConfirm] = useState(false);
	const [pasteConfirm, setPasteConfirm] = useState(false);

	const handleValue = () => {
		if (props.value) {
			try {
				const v = props.value;
				let items = [];

				if (!props.type || props.type === 'NAME_VALUE') {
					items = Object.keys(v).map(key => { return { name: key, value: v[key] }; });
				} else if (props.type === 'MIN_MAX_UOM') {
					for (let key in v) {
						let o = { name: key };
						for (let _key in v[key])
							o[_key] = v[key][_key]
						items.push(o);
					}
				}

				if (items.length > 0) return { items };

			} catch (err) {
				console.log(err);
			}
		}
		return {
			items: [{ name: '', value: null }]
		};
	};

	const methods = useForm({
		defaultValues: handleValue(),
		mode: 'onChange'
	});

	const handleSave = value => {
		const result = {};
		value.items.forEach(element => {
			if (!props.type || props.type === 'NAME_VALUE') {
				result[element.name] = element.value;
			} else if (props.type === 'MIN_MAX_UOM') {
				let _value = {};
				for (let key in element) if (key !== 'name') _value[key] = element[key];
				result[element.name] = _value;
			}
		});
		props.onSave(result);
	};

	const handleCancel = () => {
		props.onCancel();
	};

	const handleLoadFromValue = () => {
		const v = getValues(fieldPrefix + loadValueFrom);
		if (_.isObject(v)) {
			methods.setValue(
				'items',
				Object.keys(v).map(key => {
					return { name: key, value: _.isNumber(v[key]) ? v[key] * loadValueMultiplier : v[key] };
				})
			);
		}
	};

	const handleJsonCopy = value => { handleCopy(value, 'JSON') };
	const handleXlsCopy = value => { handleCopy(value, 'XLS') };

	const handleCopy = (value, type) => {
		if (value && value.items) {
			let cData = '';

			if (type === 'JSON') {
				let obj = {};
				for (let i = 0; i < value.items.length; i += 1)
					obj[value.items[i].name] = value.items[i].value;
				cData = JSON.stringify(obj);
			} else if (type === 'XLS') {
				for (let i = 0; i < value.items.length; i += 1) {
					if (cData !== '') cData += '\n';
					cData += value.items[i].name + '	' + value.items[i].value;
				}
			}

			navigator.clipboard.writeText(cData).then(() => {
				dispatch(showMessage({ message: t('to_clipboard_successful') }));
				setCopyConfirm(false)
			});
		}
	};

	const handlePaste = () => {
		const isJSON = (text) => { try { JSON.stringify(JSON.parse(text)); return true; } catch (e) { return false; } };
		const isXLS = (text) => { return text.indexOf('	') > -1 };

		const tsvJSON = (tsv) => {
			const lines = tsv.split('\n');
			let obj = {};
			for (let i = 0; i < lines.length; i += 1) {
				if (lines[i] !== '') {
					let line = lines[i].replace('\r', '').split('\t');
					obj[line[0]] = line[1];
				}
			}
			return obj;
		}

		navigator.clipboard.readText().then((clipText) => {
			if (clipText && clipText !== '' && (isJSON(clipText) || isXLS(clipText))) {

				let d = isJSON(clipText) ? JSON.parse(clipText) : tsvJSON(clipText);

				methods.setValue('items', Object.keys(d).map(key => { return { name: key, value: d[key] }; }));
				methods.setValue('items', Object.keys(d).map(key => { return { name: key, value: d[key] }; }));

				dispatch(showMessage({ message: t('from_clipboard_successful') }));
				setPasteConfirm(false)
			} else {
				dispatch(showMessage({ message: t('from_clipboard_failed') }));
			}

		}).catch(err => {

			dispatch(showMessage({ message: t('from_clipboard_failed') }));
			console.error('Failed to read clipboard contents: ', err);

		});
	};

	return (
		<Dialog open fullWidth maxWidth={props.type === 'MIN_MAX_UOM' ? 'md' : 'sm'} >

			<DialogTitle disableTypography className='border-b'>
				Value
			</DialogTitle>

			<DialogContent className='w-full p-20'>
				<FormProvider {...methods}>
					<EditFormGridComponent
						config={{ topic: 'model' }}
						fieldConfig={{
							key: 'items',
							component: EditFormGridComponent,
							defaultValue: {
								name: null,
								value: null
							},
							fields: [
								props.nameItems !== null ? {
									key: 'name',
									component: EditFormSelectField,
									required: true,
									type: 'enum',
									items: props.nameItems
								} : {
									key: 'name',
									component: EditFormTextField,
									required: true
								},
								...(props.type === 'MIN_MAX_UOM' ? [
									{
										key: 'value',
										component: EditFormTextField,
										required: true,
										type: 'number'
									},
									{
										key: 'min_value',
										component: EditFormTextField,
										required: true,
										type: 'number'
									},
									{
										key: 'max_value',
										component: EditFormTextField,
										required: true,
										type: 'number'
									},
									{
										key: 'uom',
										component: EditFormTextField,
										required: true,
									}
								] : []),
								...(!props.type || props.type === 'NAME_VALUE' ? [
									{
										key: 'value',
										component: EditFormTextField,
										type: 'number',
										required: true
									}
								] : [])
							]
						}}
					/>
				</FormProvider>
			</DialogContent>

			<DialogActions className='justify-between border-t p-20'>

				<Button onClick={() => { handleCancel(); }}>
					{t('CANCEL')}
				</Button>

				<div className='flex'>

					{loadValueFrom && (
						<Tooltip title={t('load_from_value')}>
							<IconButton onClick={() => { handleLoadFromValue(0.9); }}>
								<Icon>sync</Icon>
							</IconButton>
						</Tooltip>
					)}

					{!disableCopyPaste && <>
						<div className={'relative flex items-center justify-center ' + (copyConfirm ? 'z-10' : '')}>

							<div className={'transition-opacity ' + (copyConfirm ? 'opacity-50' : '')}>
								<Tooltip title={t('to_clipboard')}>
									<IconButton onClick={() => setCopyConfirm(true)}>
										<Icon>content_copy</Icon>
									</IconButton>
								</Tooltip>
							</div>

							<div className={'absolute flex gap-8 items-center justify-center transition-transform transform bg-white bg-opacity-90 border rounded-full p-4 shadow-xl  ' + (copyConfirm ? 'scale-100' : 'scale-0')}>

								<button className='w-32 h-32 rounded-full bg-black text-white flex items-center justify-center' onClick={methods.handleSubmit(handleJsonCopy)}>
									<BsFiletypeJson className='text-lg' />
								</button>

								<button className='w-32 h-32 rounded-full bg-black text-white flex items-center justify-center' onClick={methods.handleSubmit(handleXlsCopy)}>
									<BsFiletypeXls className='text-lg' />
								</button>

								<button className='w-32 h-32 rounded-full bg-black text-white flex items-center justify-center' onClick={() => setCopyConfirm(false)}>
									<Icon fontSize='small'>clear</Icon>
								</button>

							</div>

						</div>

						<div className={'relative flex items-center justify-center ' + (pasteConfirm ? 'z-10' : '')}>

							<div className={'transform transition-transform  ' + (pasteConfirm ? 'scale-50' : 'scale-100')}>
								<Tooltip title={t('from_clipboard')}>
									<IconButton onClick={() => setPasteConfirm(true)}>
										<Icon>content_paste_go_icon</Icon>
									</IconButton>
								</Tooltip>
							</div>

							<div className={'absolute flex gap-8 items-center justify-center transition-transform transform bg-white bg-opacity-90 border rounded-full p-4 shadow-xl  ' + (pasteConfirm ? 'scale-100' : 'scale-0')}>

								<button className='w-32 h-32 rounded-full bg-black text-white flex items-center justify-center' onClick={() => handlePaste()}>
									<Icon fontSize='small'>check</Icon>
								</button>

								<button className='w-32 h-32 rounded-full bg-black text-white flex items-center justify-center' onClick={() => setPasteConfirm(false)}>
									<Icon fontSize='small'>clear</Icon>
								</button>

							</div>

						</div>
					</>}

				</div>

				<Button onClick={methods.handleSubmit(handleSave)} color='primary' autoFocus>
					{t('SAVE')}
				</Button>

			</DialogActions>

		</Dialog >
	);
}

export default ComponentMassSomethingDialog;