import withReducer from 'app/store/withReducer';
import nBossConnection from 'modules/base/service/nBossConnection';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import AddMeasurementTaskButton from 'app/admin/common/component/AddMeasurementTaskButton';
import ShowChartButton from '../component/ShowChartButton';
import MeasurementTableService from '../service/MeasurementTableService';

const config = {
	key: 'measurementTable',
	topic: 'measurement',
	service: MeasurementTableService,
	editFormState: item => `/measurement/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['exportData', 'modify', 'delete'],
	rowButtonList: [{ key: 'runMeasurement', component: AddMeasurementTaskButton }, { key: 'showChart', component: ShowChartButton }],
	exportData: {
		tooltip: 'Export to XLSX',
		link: item => `${nBossConnection.basePath}measurementTableControl/exportToXlsx?name=${item.name}`
	},
	columns: [
		{
			key: 'name'
		}
	]
};

function MeasurementTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('measurementTable', reducer('measurementTable'))(MeasurementTable);
