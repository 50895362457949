const commonHu = {
	YES: 'Igen',
	NO: 'Nem',
	CANCEL: 'Mégse',
	SAVE: 'Mentés',
	LOADING: 'Betöltés...',
	close: 'Bezár',
	load_from_value: 'Kitöltés az értékekből',
	copy: 'Copy',
	to_clipboard: 'Másolás Vágólapra',
	to_clipboard_short: 'Másolás',
	to_clipboard_successful: 'Adatok a vágólapra másolva',
	to_clipboard_failed: 'Hiba a vágólapra történő másolás során',
	from_clipboard: 'Beillesztés a Vágólapról',
	from_clipboard_short: 'Beillesztés',
	from_clipboard_successful: 'Adatok beillesztve a vágólapról',
	from_clipboard_failed: 'Beillesztés a vágólapról sikertelen, kérjük, ellenőrizze az adatokat',
	notifications: 'Értesítők',
	delete_all: 'Összes törlése',
	emptyNotifications: 'Jelenleg nincs semmilyen értesítő.'
};
export default commonHu;
