import _ from '@lodash';
import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TransformerService from 'app/admin/workflow/service/TransformerService';
import { getCalculateValidationValue } from './WorkflowItem';
import { useWorkflow } from '../WorkflowProvider';

export const warningOnly = _errorMessages => {
	let result = true;
	_errorMessages.forEach(err => {
		if (err.severity === 'Error') result = false;
	});
	return result;
};

export const parseErrors = (procDef, validation) => {
	const _errorMessages = [];
	if (validation) _errorMessages.push(...validation.entries);
	if (procDef != null) {
		if (_.isObject(procDef) && procDef.validation.entries.length > 0) _errorMessages.push(...procDef.validation.entries);
		else if (_.isString(procDef))
			_errorMessages.push({
				severity: 'Error',
				message: procDef
			});
	}
	return _errorMessages;
};

function TransformationPropertiesDialog(props) {
	const { schema, titleKey, titleContent, mergeValue, mergeProcess, maxWidth, converter } = props;
	const { calculate, data, showPropertiesDialog, setShowPropertiesDialog, deleteNewCanceledTransformation, updateTransformationProperties } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation', 'common']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);
	const [errorMessages, setErrorMessages] = useState(false);
	const [warningConfirm, setWarningConfirm] = useState(false);
	const saveConfirmedRef = useRef(false);

	const validation = getCalculateValidationValue(calculate, item.name);

	const _mergeValue = _.isFunction(mergeValue)
		? mergeValue
		: value => {
			return value;
		};

	const _mergeProcess = _.isFunction(mergeProcess)
		? mergeProcess
		: (value, definition) => {
			return { ...value, ...definition };
		};

	const _converter = _.isFunction(converter)
		? converter
		: value => {
			return value;
		};

	useEffect(() => {
		if (validation) {
			setErrorMessages(validation.entries);
		}
	}, []);

	const methods = useForm({
		defaultValues: item,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});
	const { handleSubmit, clearErrors } = methods;

	const handleSave = model => {
		const value = _mergeValue(model);
		clearErrors();
		setErrorMessages([]);
		TransformerService.processDefinition(_converter(value))
			.then(procDef => {
				const _errorMessages = parseErrors(procDef, validation);
				if (_errorMessages.length > 0) {
					if (warningOnly(_errorMessages)) {
						if (saveConfirmedRef.current) {
							updateTransformationProperties(item, _.isObject(procDef.definition) ? _mergeProcess(value, procDef.definition) : value);
							setShowPropertiesDialog(null);
						} else {
							setErrorMessages(_errorMessages);
							saveConfirmedRef.current = false;
							setWarningConfirm(true);
						}
					} else {
						setErrorMessages(_errorMessages);
					}
				} else {
					updateTransformationProperties(item, _.isObject(procDef.definition) ? _mergeProcess(value, procDef.definition) : value);
					setShowPropertiesDialog(null);
				}
			})
			.catch(e => {
				updateTransformationProperties(item, value);
				setShowPropertiesDialog(null);
			});
	};

	const handleCancel = () => {
		setErrorMessages([]);
		deleteNewCanceledTransformation(item);
		setShowPropertiesDialog(null);
	};

	return (
		<Dialog open fullWidth maxWidth={maxWidth}>
			{titleContent ? (
				<DialogTitle disableTypography className="border-b p-0">
					<div className="flex flex-row justify-between items-center">
						<div className="px-20 font-bold">
							<div>{t(titleKey)}</div>
						</div>
						{titleContent}
					</div>
				</DialogTitle>
			) : (
				<DialogTitle disableTypography className="border-b font-bold">
					{t(titleKey)}
				</DialogTitle>
			)}
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					{props.children}
					{errorMessages.length > 0 && errorMessages.map((err, i) => <p className="text-red" key={i}>{`${err.severity}: ${err.message}`}</p>)}
					{warningConfirm && <p className="font-bold pt-20">{t('warningConfirmText')}</p>}
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				{warningConfirm ? (
					<>
						<Button
							onClick={() => {
								setWarningConfirm(false);
							}}
						>
							{t('common:NO')}
						</Button>
						<Button
							onClick={() => {
								saveConfirmedRef.current = true;
								handleSubmit(handleSave)();
								setWarningConfirm(false);
							}}
							color="primary"
							autoFocus
						>
							{t('common:YES')}
						</Button>
					</>
				) : (
					<>
						<Button
							onClick={() => {
								handleCancel();
							}}
						>
							{t('cancel')}
						</Button>
						<Button onClick={handleSubmit(handleSave)} color="primary" autoFocus>
							{t('save')}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
}
export default TransformationPropertiesDialog;
