const taskManagerHu = {
	taskManagerTable: 'Task Manager',
	name: 'Name',
	status: 'Status',
	type: 'Type',
	ts: 'Timestamp',
	result: 'Result',
	error: 'Error',
	user_name: 'Username',
	message: 'Message',
	ts_result: 'Result Timestamp',
	file_count: 'Files',
	enabled: 'Enabled',
	schedule: 'Schedule',
	ok_count: 'OK Count',
	fail_count: 'Fail Count',
	last_started: 'Last Started',
	last_status: 'Last Status',
	last_timestamp: 'Last Timestamp',
	last_message: 'Last Message',
	last_result: 'Last Result',
};

export default taskManagerHu;
