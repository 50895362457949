import { authRoles } from 'app/auth';
import i18next from 'i18next';
// import OptimizationPipelineTable from './table/OptimizationPipelineTable';
import OptimizationPipeline from './OptimizationPipeline';
import OptimizationPipelineProvider from './component/OptimizationPipelineProvider';

import en from './i18n/en';
import hu from './i18n/hu';

i18next.addResourceBundle('en', 'optimizationPipeline', en);
i18next.addResourceBundle('hu', 'optimizationPipeline', hu);

export default [
	{
		path: '/optimization-pipeline/:sessionNameParam?',
		component: () => (<OptimizationPipelineProvider><OptimizationPipeline /></OptimizationPipelineProvider>),
		auth: authRoles.taskManager
	},
	{
		path: '/optimization-pipeline/edit/:id/:tabKey?',
		component: () => (<OptimizationPipelineProvider><OptimizationPipeline /></OptimizationPipelineProvider>),
		auth: authRoles.taskManager
	}
];
