import { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Button, Dialog, DialogContent, DialogTitle, Select, MenuItem } from '@material-ui/core';
import { TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import format from 'date-fns/format';
import { FaTimes } from 'react-icons/fa';
import { sub } from 'date-fns';
import MeasurementTableService from 'app/admin/measurement/service/MeasurementTableService';

import { formatLabelValue } from 'app/admin/common/util/CommonUtil';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';

export default function ChartPopup(props) {
    const [meta, setMeta] = useState(null);
    const [data, setData] = useState(null);
    const [keys, setKeys] = useState([]);
    const [selectedKey, setSelectedKey] = useState(null);
    const [filteredData, setFilteredData] = useState(null);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    useEffect(() => {
        MeasurementTableService.getTimeseriesDetails(props.name).then(_data => { setMeta(_data) });
    }, [props.name]);

    useEffect(() => {
        if (meta) {
            setKeys(meta.field_names);

            const from = new Date(meta.ts.min);
            const to = new Date(meta.ts.max);
            const _sub = sub(to, { months: 3 });

            setDateFrom(_sub < from ? from : _sub);
            setDateTo(to);

            if (!data) MeasurementTableService.getData(props.name, meta.ts.min, meta.ts.max).then(_data => { setData(_data); });
        }
    }, [meta]);

    useEffect(() => {
        if (keys.length > 0 && selectedKey === null) setSelectedKey(keys[0]);
    }, [keys]);

    useEffect(() => {
        if (data && selectedKey && dateFrom && dateTo) {
            let filteredByKey = getDataByKey(selectedKey, data);
            let filteredByDate = [];

            for (let i = 0; i < filteredByKey.length; i += 1) {
                const date = new Date(filteredByKey[i].ts);
                if (date >= dateFrom && date <= dateTo)
                    filteredByDate.push(filteredByKey[i]);
            }

            setFilteredData(filteredByDate);
        }
    }, [data, selectedKey, dateFrom, dateTo]);

    // const getKeysFromData = (_data) => {
    //     let _keys = [];
    //     for (let i = 0; i < _data.length; i += 1)
    //         for (let key in _data[i])
    //             if (_keys.indexOf(key) === -1 && key !== 'RL_Outlier' && key !== 'ML_Outlier' && key !== 'ts') _keys.push(key);
    //     return _keys;
    // };

    const getDataByKey = (_key, _data) => {
        let __data = [];
        for (let i = 0; i < _data.length; i += 1)
            for (let key in _data[i])
                if (key === _key)
                    __data.push({ value: _data[i][key], ts: /*format(new Date(_data[i].ts), 'yy-MM-dd HH:mm:ss')*/_data[i].ts, RL_Outlier: _data[i].RL_Outlier || false, ML_Outlier: _data[i].ML_Outlier || false });
        return __data;
    };

    const getTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='flex flex-col gap-0.5 bg-secondary p-8 text-white text-xs shadow-lg bg-black opacity-75'>
                    <div className='font-medium'>{formatLabelValue(payload[0].value, defaultLabelStyle())}</div>
                </div>
            );
        }
        return null;
    };

    const CustomizedDot = (_props) => {
        const { cx, cy, stroke, payload, value } = _props;

        if (payload.RL_Outlier) { return (<circle cx={cx - 2} cy={cy - 2} r={4} fill='red' stroke='white' strokeWidth={2} />); }

        if (payload.ML_Outlier) {
            return (<svg x={cx - 4} y={cy - 4} width={8} height={8} fill="orange" viewBox="0 0 86.11 86.11">
                <path class="cls-1" d="M85,7.72l-6.6-6.6a3.86,3.86,0,0,0-5.43,0L45.77,28.32a3.84,3.84,0,0,1-5.43,0L13.15,1.12a3.86,3.86,0,0,0-5.43,0l-6.6,6.6a3.86,3.86,0,0,0,0,5.43l27.2,27.19a3.84,3.84,0,0,1,0,5.43L1.12,73a3.86,3.86,0,0,0,0,5.43L7.72,85a3.84,3.84,0,0,0,5.43,0L40.34,57.8a3.84,3.84,0,0,1,5.43,0L73,85a3.84,3.84,0,0,0,5.43,0l6.6-6.6A3.84,3.84,0,0,0,85,73L57.8,45.77a3.84,3.84,0,0,1,0-5.43L85,13.15A3.84,3.84,0,0,0,85,7.72Z" />
            </svg>);
        }

        return null;
    };

    const isDataAvailable = () => {
        return meta && meta.ts.min && meta.ts.max;
    };

    return (
        <Dialog open fullWidth maxWidth={isDataAvailable() ? 'lg' : 'sm'}>

            <DialogTitle disableTypography className='border-b flex flex-col px-0'>

                <div className={'flex items-center justify-between gap-32 px-32  ' + (isDataAvailable() ? 'border-b pb-14' : '')}>

                    <div className='whitespace-nowrap'>{props.title || 'Timeseries'}</div>

                    <Button onClick={() => { props.onClose(); }}>
                        Close
                    </Button>

                </div>

                {isDataAvailable() && <div className='w-full flex shadow-inner px-32 pt-14'>

                    <div className='w-full flex items-end rounded-xl bg-grey-100 py-10 gap-32 shadow px-16'>

                        <Select className='flex-1 h-36 mb-4' variant="outlined" value={selectedKey} onChange={e => setSelectedKey(e.target.value)}>
                            {keys && keys.map((item) => <MenuItem key={item} onClick={() => setSelectedKey(item)} value={item}>{item}</MenuItem>)}
                        </Select>

                        <DatePick className='flex-1' label='From' value={dateFrom} minDate={new Date(meta.ts.min)} maxDate={new Date(meta.ts.max)} onChange={(v) => setDateFrom(v)} />
                        <DatePick className='flex-1' label='To' value={dateTo} minDate={new Date(meta.ts.min)} maxDate={new Date(meta.ts.max)} onChange={(v) => setDateTo(v)} />

                    </div>

                </div>}

            </DialogTitle>

            <DialogContent className='w-full flex flex-col p-20' style={{ height: isDataAvailable() ? '520px' : 'auto' }}>

                {(meta && (!meta.ts.min || !meta.ts.max)) && <div className='text-center'>No data available</div>}

                {(isDataAvailable() && filteredData && filteredData.length > 0) && <div className='w-full flex-1'>

                    <ResponsiveContainer width='100%' height='100%'>

                        <LineChart data={filteredData} margin={{ top: 10, left: 10, right: 10, bottom: 10 }}>

                            {/* <ReferenceLine y={1500} stroke="#22d3ee" strokeDasharray="3 3" /> */}

                            <YAxis
                                tickMargin={10}
                                interval='equidistantPreserveStart'
                                tickCount={10}
                                type='number'
                                tickFormatter={(tick) => formatLabelValue(tick, { thousandsSeparator: false, decimalPlaces: 2, displayAsPercentage: false, showUnit: false })}
                                domain={['dataMin', 'dataMax']}
                            />

                            <XAxis
                                tickMargin={10}
                                tickCount={10}
                                tickFormatter={(tick) => format(new Date(tick), 'yyyy.MM.dd. - HH:mm:ss')}
                                dataKey='ts'
                                interval='equidistantPreserveStart'
                            />

                            <CartesianGrid strokeDasharray='3 3' />

                            <Tooltip content={getTooltip} cursor={{ fill: 'transparent' }} />

                            <Line type='monotone' dataKey='value' stroke='#252f3e' strokeWidth={2} dot={<CustomizedDot />} />

                        </LineChart>

                    </ResponsiveContainer>

                </div>}

                {/* <div className='flex gap-16 items-center justify-center bg-grey-100 rounded-full p-6'>

                    <div className='flex items-center gap-6'>
                        <div className='w-10 h-10 bg-red-600 rounded-full border-2 border-white' />
                        <div>Rule-Based Anomalies</div>
                    </div>

                    <div className='flex items-center gap-6'>
                        <FaTimes className='text-orange-600' />
                        <div>ML-Based Anomalies</div>
                    </div>

                </div> */}

            </DialogContent>

        </Dialog>
    );
}

function DatePick(props) {
    // console.log(props.value);
    return (
        <DateTimePicker
            className={props.className}
            label={props.label}
            margin='dense'
            value={props.value}
            onChange={e => props.onChange(e)}
            ampm={false}
            minDate={props.minDate}
            maxDate={props.maxDate}
            // views={['hours', 'minutes', 'seconds']}
            format='yyyy MMMM d. HH:mm'
            cancelLabel='Vissza'
            okLabel='Ok'
            clearLabel='Törlés'
            inputVariant='outlined'
            clearable
        />
    );
}