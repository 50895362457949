import React, { useState } from 'react';
import { IconButton, Tooltip, TableCell } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import AddMeasurementDialog from './AddMeasurementDialog';

function AddMeasurementButton(props) {
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <TableCell className='w-auto text-center' padding='none'>

            <Tooltip title='Add Measurement'>
                <span>
                    <IconButton onClick={e => { setOpenDialog(true); e.preventDefault(); }}>
                        <Add fontSize='small' />
                    </IconButton>
                </span>
            </Tooltip>

            {openDialog && (
                <AddMeasurementDialog
                    readerId={props.item.id}
                    readerName={props.item.name}
                    onCancel={() => setOpenDialog(false)}
                    onSave={result => {
                        // onChange(result);
                        setOpenDialog(false);
                    }}
                />
            )}

        </TableCell>
    );
}

export default AddMeasurementButton;
