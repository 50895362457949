import _ from '@lodash';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';

import TaskManagerService from 'app/admin/taskManager/service/TaskManagerService';

export function TaskManagerScheduleDialog(props) {
    const history = useHistory();
    const { t } = useTranslation(['common']);
    const dispatch = useDispatch();

    const schema = yup.object().shape({
        // minute: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
        // hour: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
        // day: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
        // month: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
    });

    const getDefaultValues = () => {
        let defaultValues = {};

        const s = props.value.split(',')

        defaultValues.minute = s[0];
        defaultValues.hour = s[1];
        defaultValues.day = s[2];
        defaultValues.month = s[3];

        return defaultValues;
    };

    const methods = useForm({
        defaultValues: getDefaultValues(),
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    const handleSave = value => {
        for (let key in value) if (value[key] === '*') value[key] = null;
        TaskManagerService.updateSchedule(props.name, value, {}).then(response => handleResponse(response)).catch(err => handleCatch(err));
    };

    const handleResponse = (response) => {
        dispatch(showMessage({ message: 'Scheduler successfully updated!' }));
        dispatch({ type: `taskManagerTable/event/setNeedDataReload` });
        props.onCancel();
    };

    const handleCatch = (error) => {
        dispatch(showMessage({ message: 'Error while updating Scheduler!' }));
    };

    const handleCancel = () => {
        props.onCancel();
    };

    return (
        <Dialog open fullWidth>

            <DialogTitle disableTypography className="border-b">
                Update Schedule
            </DialogTitle>

            <DialogContent className="p-20 flex gap-8">

                <FormProvider {...methods}>

                    <Controller
                        control={methods.control}
                        name="minute"
                        render={({ field, fieldState }) => (
                            <EditFormTextField field={field} fieldState={fieldState} config={{ topic: 'common' }} fieldConfig={{ key: 'minute', text: 'Minute', required: false }} />
                        )}
                    />

                    <Controller
                        control={methods.control}
                        name="hour"
                        render={({ field, fieldState }) => (
                            <EditFormTextField field={field} fieldState={fieldState} config={{ topic: 'common' }} fieldConfig={{ key: 'hour', text: 'Hour', required: false }} />
                        )}
                    />

                    <Controller
                        control={methods.control}
                        name="day"
                        render={({ field, fieldState }) => (
                            <EditFormTextField field={field} fieldState={fieldState} config={{ topic: 'common' }} fieldConfig={{ key: 'day', text: 'Day', required: false }} />
                        )}
                    />

                    <Controller
                        control={methods.control}
                        name="month"
                        render={({ field, fieldState }) => (
                            <EditFormTextField field={field} fieldState={fieldState} config={{ topic: 'common' }} fieldConfig={{ key: 'month', text: 'Month', required: false }} />
                        )}
                    />

                </FormProvider>

            </DialogContent>

            <DialogActions className="justify-between border-t p-20">

                <Button onClick={() => { handleCancel(); }}>
                    {t('CANCEL')}
                </Button>

                <Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
                    Update
                </Button>

            </DialogActions>

        </Dialog >
    );
}
export default TaskManagerScheduleDialog;