import React, { useState } from 'react';
import { IconButton, Tooltip, TableCell } from '@material-ui/core';
import Update from '@material-ui/icons/Update';
import TaskManagerScheduleDialog from './TaskManagerScheduleDialog';

function TaskManagerScheduleButton(props) {
    console.log(props);
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <TableCell className='w-auto text-center' padding='none'>

            <Tooltip title='Update Schedule'>
                <span>
                    <IconButton onClick={e => { setOpenDialog(true); e.preventDefault(); }}>
                        <Update fontSize='small' />
                    </IconButton>
                </span>
            </Tooltip>

            {openDialog && (
                <TaskManagerScheduleDialog value={props.item.schedule} name={props.item.name} onCancel={() => setOpenDialog(false)} />
            )}

        </TableCell>
    );
}

export default TaskManagerScheduleButton;
