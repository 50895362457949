import nBossConnection from 'modules/base/service/nBossConnection';

class TaskManagerService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'taskManagerControl';
	}

	/**
	 * @param {string} optimCaseName
	 * @return {Promise<string>}
	 */
	addOptimCase(optimCaseName) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/addOptimCase`, {
					optimCaseName
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} fittingCaseName
	 * @return {Promise<string>}
	 */
	addFittingCase(fittingCaseName) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/addFittingCase`, {
					fittingCaseName
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {string} aspectName
	 * @param {string} transformerName
	 * @param {Object} values
	 * @return {Promise<string>}
	 */
	addCalculate(id, aspectName, transformerName, values) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/addCalculate`, {
					id,
					aspectName,
					transformerName,
					values
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {number} aspectId
	 * @param {number} transformerId
	 * @param {Object} values
	 * @return {Promise<string>}
	 */
	addWhatIf(id, aspectId, transformerId, values) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/addWhatIf`, {
					id,
					aspectId: aspectId || null,
					transformerId: transformerId || null,
					values
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<any>}
	 */
	getQueueCounts() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getQueueCounts`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getQueueCountByAspect(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getQueueCountByAspect`, { id })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {String} groupName
	 * @return {Promise<any>}
	 */
	listFiles(id, groupName) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/listFiles`, {
					id, groupName
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getResult(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getResult`, {
					id
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	* @param {number} taskManagerId
	* @param {string} name
	* @return {Promise<any>}
	*/
	getFileContent(taskManagerId, name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getFileContent`, {
					taskManagerId,
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} measurementId
	 * @param {number} readerId
	 * @param {Object} values
	 * @return {Promise<string>}
	 */
	addPiMeasurementReader(measurementId, readerId, values) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/addPiMeasurementReader`, {
					measurementId,
					readerId,
					values,
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	* @param {string} name
	* @param {number} enabled
	* @return {Promise<any>}
	*/
	updateEnabled(name, enabled) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/updateEnabled`, {
					name,
					enabled
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	* @param {string} name
	* @param {dto} dto
	* @return {Promise<any>}
	*/
	updateSchedule(name, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/updateSchedule`, {
					name,
					dto
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new TaskManagerService();
