import OutOfDistributionEditDto from './OutOfDistributionEditDto';

export class ClassSvmModelEditDto extends OutOfDistributionEditDto {
	constructor() {
		super();
		/** @type {number} */
		this.max_iter = null;
		/** @type {number} */
		this.initial_temp = null;
		/** @type {number} */
		this.restart_temp_ratio = null;
		/** @type {number} */
		this.visit = null;
		/** @type {number} */
		this.accept = null;
	}

	/**
	 * @typedef {Object} _ClassSvmModelEditDtoProps
	 * @property {number} max_iter
	 * @property {number} initial_temp
	 * @property {number} restart_temp_ratio
	 * @property {number} visit
	 * @property {number} accept
	 *
	 * @typedef {import("./OptimizerEditDto").OptimizerEditDtoProps & _ClassSvmModelEditDtoProps} ClassSvmModelEditDtoProps
	 */
	/**
	 *
	 * @param {ClassSvmModelEditDtoProps} props
	 * @returns {ClassSvmModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.max_iter = props.max_iter;
		this.initial_temp = props.initial_temp;
		this.restart_temp_ratio = props.restart_temp_ratio;
		this.visit = props.visit;
		this.accept = props.accept;
		return this;
	}
}

export default ClassSvmModelEditDto;
